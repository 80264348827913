import _ from 'lodash'
import pako from 'pako'
import { combineReducers } from 'redux'
import { getFormValues } from 'redux-form'
import invariant from 'tiny-invariant'
import url from 'url'

import { PassthroughContentsError } from '../error'
import { MATCH_ENTITIES_FORM } from '../forms'
import * as paths from '../paths'
import { getFormValidationErrors, handleAPIResponse } from '../util'

import { handleAppError } from './error'
import { clearProcessVersionTooOld } from './process'

const prefix = 'insertion/project'

export const REQUEST_LIST = `${prefix}/REQUEST_LIST`
export const RECEIVE_LIST = `${prefix}/RECEIVE_LIST`
export const SHOW_DELETE_MODAL = `${prefix}/SHOW_DELETE_MODAL`
export const HIDE_DELETE_MODAL = `${prefix}/HIDE_DELETE_MODAL`
export const SET_PROJECT_STEP = `${prefix}/SET_PROJECT_STEP`
export const REQUEST_VALIDATE = `${prefix}/REQUEST_VALIDATE`
export const RECEIVE_VALIDATE = `${prefix}/RECEIVE_VALIDATE`
export const SET_ACTIVE_FILE = `${prefix}/SET_ACTIVE_FILE`
export const REQUEST_FILE_EDIT = `${prefix}/REQUEST_FILE_EDIT`
export const RECEIVE_FILE_EDIT = `${prefix}/RECEIVE_FILE_EDIT`
export const REQUEST_ENTITIES = `${prefix}/REQUEST_ENTITIES`
export const RECEIVE_ENTITIES = `${prefix}/RECEIVE_ENTITIES`
export const REQUEST_TAGS = `${prefix}/REQUEST_TAGS`
export const RECEIVE_TAGS = `${prefix}/RECEIVE_TAGS`
export const REQUEST_UNMATCHED_ENTITIES = `${prefix}/REQUEST_UNMATCHED_ENTITIES`
export const RECEIVE_UNMATCHED_ENTITIES = `${prefix}/RECEIVE_UNMATCHED_ENTITIES`
export const SET_SHOULD_PARSE = `${prefix}/SET_SHOULD_PARSE`
export const SET_DID_PARSE = `${prefix}/SET_DID_PARSE`
export const SHOW_NEW_ENTITY_MODAL = `${prefix}/SHOW_NEW_ENTITY_MODAL`
export const CANCEL_NEW_ENTITY = `${prefix}/CANCEL_NEW_ENTITY`
export const REQUEST_ORGANISATION_CLASSES = `${prefix}/REQUEST_ORGANISATION_CLASSES`
export const RECEIVE_ORGANISATION_CLASSES = `${prefix}/RECEIVE_ORGANISATION_CLASSES`
export const JCM_PROCESS_STARTED = `${prefix}/JCM_PROCESS_STARTED`
export const JCM_PROCESS_STOPPED = `${prefix}/JCM_PROCESS_STOPPED`
export const SET_JCM_PROCESS_STATUS = `${prefix}/SET_JCM_PROCESS_STATUS`
export const SET_JCM_PROCESS_MONITOR_URL = `${prefix}/SET_JCM_PROCESS_MONITOR_URL`
export const JCM_INSERT_STARTED = `${prefix}/JCM_INSERT_STARTED`
export const JCM_INSERT_STOPPED = `${prefix}/JCM_INSERT_STOPPED`
export const SET_JCM_INSERT_STATUS = `${prefix}/SET_JCM_INSERT_STATUS`
export const SET_JCM_INSERT_MONITOR_URL = `${prefix}/SET_JCM_INSERT_MONITOR_URL`
export const SET_CAN_ADVANCE_STEP = `${prefix}/SET_CAN_ADVANCE_STEP`
export const REQUEST_TIMESTAMPS = `${prefix}/REQUEST_TIMESTAMPS`
export const RECEIVE_TIMESTAMPS = `${prefix}/RECEIVE_TIMESTAMPS`
export const REQUEST_FOOTNOTES = `${prefix}/REQUEST_FOOTNOTES`
export const RECEIVE_FOOTNOTES = `${prefix}/RECEIVE_FOOTNOTES`

// Reducer

export const projectListInitialState = {
  projects: [],
  projectLookup: {},
  isLoading: false,
  deleteModal: {
    show: false,
    project: null,
  },
}

export function projectListReducer(state = projectListInitialState, action) {
  const deleteModal = state.deleteModal

  switch (action.type) {
    case REQUEST_LIST:
      return { ...state, isLoading: true }
    case RECEIVE_LIST:
      return {
        ...state,
        projects: action.projects,
        projectLookup: action.projectLookup,
        isLoading: false,
      }
    case SHOW_DELETE_MODAL:
      return {
        ...state,
        deleteModal: { ...deleteModal, show: true, project: action.project },
      }
    case HIDE_DELETE_MODAL:
      return { ...state, deleteModal: { ...deleteModal, show: false } }
    default:
      return state
  }
}

export const projectsSelector = (state) => state.project.projectList.projects

export const projectInitialState = {
  step: null,
  validation: {
    errors: {},
    isLoading: false,
    fileData: {},
    activeFile: null,
    filesLoading: [],
  },
  entities: {
    isLoading: false,
    unmatchedStates: [],
    unmatchedOrgs: [],
    showNewModal: false,
    inputNameForNew: null,
    organisationClasses: [],
    classesLoading: false,
  },
  isProcessing: false,
  processingStatus: null,
  processingMonitorUrl: null,
  isInserting: false,
  insertingStatus: null,
  insertingMonitorUrl: null,
  canAdvanceStep: false,
  timestamps: {},
  timestampsLoading: false,
  shouldParse: false,
  footnotesLoading: false,
  footnotes: [],
  lastSaved: null,
}

export function projectReducer(state = projectInitialState, action) {
  let errors
  let entities
  let fileData
  let validation

  switch (action.type) {
    case SET_PROJECT_STEP:
      return { ...state, step: action.step }
    case REQUEST_VALIDATE:
      validation = { ...state.validation, isLoading: true }
      return { ...state, validation }
    case RECEIVE_VALIDATE:
      validation = {
        ...state.validation,
        isLoading: false,
        errors: action.errors,
        fileData: action.fileData,
      }
      return { ...state, validation }
    case SET_ACTIVE_FILE:
      validation = { ...state.validation, activeFile: action.file }
      return { ...state, validation }
    case RECEIVE_FILE_EDIT:
      errors = { ...state.validation.errors, ...action.errors }
      validation = { ...state.validation, errors, filesLoading: [] }
      return { ...state, validation }
    case REQUEST_FILE_EDIT:
      fileData = { ...state.validation.fileData, ...action.fileData }
      validation = { ...state.validation, fileData, filesLoading: action.files }
      return { ...state, validation }
    case REQUEST_UNMATCHED_ENTITIES:
      entities = { ...state.entities, isLoading: true }
      return { ...state, entities }
    case RECEIVE_UNMATCHED_ENTITIES:
      entities = {
        ...state.entities,
        isLoading: false,
        unmatchedStates: action.unmatchedStates,
        unmatchedOrgs: action.unmatchedOrgs,
      }
      return { ...state, entities }
    case SHOW_NEW_ENTITY_MODAL:
      entities = {
        ...state.entities,
        showNewModal: true,
        inputNameForNew: action.inputName,
      }
      return { ...state, entities }
    case CANCEL_NEW_ENTITY:
      entities = {
        ...state.entities,
        showNewModal: false,
        inputNameForNew: null,
      }
      return { ...state, entities }
    case REQUEST_ORGANISATION_CLASSES:
      entities = { ...state.entities, classesLoading: true }
      return { ...state, entities }
    case RECEIVE_ORGANISATION_CLASSES:
      entities = {
        ...state.entities,
        classesLoading: false,
        organisationClasses: action.organisationClasses,
      }
      return { ...state, entities }
    case JCM_PROCESS_STARTED:
      return { ...state, isProcessing: true, processingStatus: null }
    case JCM_PROCESS_STOPPED:
      return {
        ...state,
        isProcessing: false,
        processingStatus: null,
        processingMonitorUrl: null,
      }
    case SET_JCM_PROCESS_STATUS:
      return { ...state, processingStatus: action.status }
    case SET_JCM_PROCESS_MONITOR_URL:
      return { ...state, processingMonitorUrl: action.monitorUrl }
    case JCM_INSERT_STARTED:
      return { ...state, isInserting: true, insertingStatus: null }
    case JCM_INSERT_STOPPED:
      return {
        ...state,
        isInserting: false,
        insertingStatus: null,
        insertingMonitorUrl: null,
      }
    case SET_JCM_INSERT_STATUS:
      return { ...state, insertingStatus: action.status }
    case SET_JCM_INSERT_MONITOR_URL:
      return { ...state, insertingMonitorUrl: action.monitorUrl }
    case SET_CAN_ADVANCE_STEP:
      return { ...state, canAdvanceStep: action.canAdvanceStep }
    case REQUEST_TIMESTAMPS:
      return { ...state, timestampsLoading: true }
    case RECEIVE_TIMESTAMPS:
      return {
        ...state,
        timestampsLoading: false,
        timestamps: action.timestamps,
      }
    case SET_SHOULD_PARSE:
      return { ...state, shouldParse: true }
    case SET_DID_PARSE:
      return { ...state, shouldParse: false }
    case REQUEST_FOOTNOTES:
      return { ...state, footnotesLoading: true }
    case RECEIVE_FOOTNOTES:
      return { ...state, footnotesLoading: false, footnotes: action.footnotes }
    default:
      return state
  }
}

export const auxiliaryInitialState = {
  entities: {},
  loadingEntities: false,
  entitiesById: {},
  tags: [],
  tagsLoading: false,
}

export function auxiliaryReducer(state = auxiliaryInitialState, action) {
  switch (action.type) {
    case REQUEST_ENTITIES:
      return { ...state, loadingEntities: true }
    case RECEIVE_ENTITIES:
      return {
        ...state,
        entities: action.entities,
        loadingEntities: false,
        entitiesById: {
          ..._.keyBy(action.entities.countries, 'id'),
          ..._.keyBy(action.entities.organisations, 'id'),
        },
      }
    case REQUEST_TAGS:
      return { ...state, tagsLoading: true }
    case RECEIVE_TAGS:
      return { ...state, tags: action.tags, tagsLoading: false }
    default:
      return state
  }
}

export default combineReducers({
  projectList: projectListReducer,
  project: projectReducer,
  auxiliary: auxiliaryReducer,
})

// Action creators

export function showNewEntityModal(inputName) {
  return { type: SHOW_NEW_ENTITY_MODAL, inputName }
}

export function cancelNewEntity() {
  return { type: CANCEL_NEW_ENTITY }
}

export function requestEntities() {
  return { type: REQUEST_ENTITIES }
}

export function receiveEntities(entities) {
  return { type: RECEIVE_ENTITIES, entities }
}

export function fetchEntities(ifRequired = false) {
  return (dispatch, getState) => {
    if (ifRequired) {
      const state = getState()
      const entities = entitiesSelector(state)
      const isLoading = entitiesLoadingSelector(state)
      if (isLoading) return
      if (
        _.isArray(entities.countries) &&
        _.isArray(entities.organisations) &&
        entities.countries.length &&
        entities.organisations.length
      ) {
        return
      }
    }
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestEntities())
    return fetch('/api/entities', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveEntities(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export const entitiesLoadingSelector = (state) =>
  state.project.auxiliary.loadingEntities

export const entitiesSelector = (state) => state.project.auxiliary.entities

export const entitiesByIdSelector = (state) =>
  state.project.auxiliary.entitiesById

export function requestTags() {
  return { type: REQUEST_TAGS }
}

export function receiveTags(tags) {
  return { type: RECEIVE_TAGS, tags }
}

export function fetchTags() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestTags())
    return fetch('/api/tags', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveTags(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function receiveFileEdit(errors) {
  return { type: RECEIVE_FILE_EDIT, errors }
}

export function requestFileEdit(fileData) {
  return { type: REQUEST_FILE_EDIT, files: Object.keys(fileData), fileData }
}

export function sendFileEdit(projectId, fileData) {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      method: 'POST',
      body: pako.gzip(JSON.stringify(fileData)),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Content-Encoding': 'gzip',
      },
    }
    dispatch(requestFileEdit(fileData))
    return fetch(`/api/project/jcm/${projectId}/edit_files`, options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveFileEdit(json.errors))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestOrganisationClasses() {
  return { type: REQUEST_ORGANISATION_CLASSES }
}

export function receiveOrganisationClasses(organisationClasses) {
  return { type: RECEIVE_ORGANISATION_CLASSES, organisationClasses }
}

export function fetchOrganisationClasses() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestOrganisationClasses())
    return fetch('/api/organisations/classes', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveOrganisationClasses(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestUnmatchedEntities() {
  return { type: REQUEST_UNMATCHED_ENTITIES }
}

export function receiveUnmatchedEntities(unmatched_entities) {
  const { states, orgs } = unmatched_entities
  return {
    type: RECEIVE_UNMATCHED_ENTITIES,
    unmatchedStates: states,
    unmatchedOrgs: orgs,
  }
}

export function setShouldParse() {
  return { type: SET_SHOULD_PARSE }
}

export function setDidParse() {
  return { type: SET_DID_PARSE }
}

export function fetchUnmatchedEntities(projectId) {
  return (dispatch) => {
    dispatch(requestUnmatchedEntities())
    return fetch(`/api/project/jcm/${projectId}/unmatched_entities`, {
      credentials: 'same-origin',
    })
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveUnmatchedEntities(json))
          },
          (json, error) => {
            if (json.should_parse) {
              dispatch(receiveUnmatchedEntities({ states: [], orgs: [] }))
              dispatch(setShouldParse())
            } else {
              throw error
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestProjects() {
  return { type: REQUEST_LIST }
}

export function setProjectStep(step) {
  return { type: SET_PROJECT_STEP, step }
}

export function receiveProjects(projects) {
  let projectLookup = {}
  projects.forEach((project) => (projectLookup[project.id] = project))
  return { type: RECEIVE_LIST, projects, projectLookup }
}

export function showDeleteModal(project) {
  return { type: SHOW_DELETE_MODAL, project }
}

export function hideDeleteModal() {
  return { type: HIDE_DELETE_MODAL }
}

export function fetchProjects() {
  return (dispatch) => {
    dispatch(requestProjects())
    return fetch('/api/projects', { credentials: 'same-origin' })
      .then(
        handleAPIResponse(
          (json) => {
            if ('projects' in json) {
              dispatch(receiveProjects(json.projects))
            } else {
              throw new Error('Malformed JSON response missing projects key.')
            }
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestValidate() {
  return { type: REQUEST_VALIDATE }
}

export function receiveValidate(errors, fileData) {
  return { type: RECEIVE_VALIDATE, errors, fileData }
}

export function fetchValidation(projectId) {
  return (dispatch) => {
    dispatch(requestValidate())
    const options = { credentials: 'same-origin' }
    return fetch(`/api/project/jcm/${projectId}/validate`, options)
      .then(
        handleAPIResponse(
          (json) => {
            if ('errors' in json && 'file_data' in json) {
              dispatch(receiveValidate(json.errors, json.file_data))
            } else {
              throw new Error('Malformed JSON response missing projects key.')
            }
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function saveValidation(
  projectId,
  { noRedirect = false, history } = {},
) {
  invariant(
    noRedirect || history !== undefined,
    'history must be passed unless noRedirect is true',
  )
  return (dispatch) => {
    dispatch(requestValidate())
    const options = { credentials: 'same-origin', method: 'POST' }
    return fetch(`/api/project/jcm/${projectId}/validate?save=true`, options)
      .then(
        handleAPIResponse(
          (json) => {
            if ('errors' in json && 'file_data' in json) {
              dispatch(setDidParse())
              dispatch(receiveValidate(json.errors, json.file_data))

              const valid = _(json.errors)
                .values()
                .reduce((isOk, lst) => isOk && _.isEmpty(lst), true)

              if (valid && !noRedirect) {
                history.push(
                  paths.jcmProjectFilter({ projectId: projectId.toString() }),
                )
              }
            } else {
              throw new Error(
                'Malformed JSON response missing errors and file_data keys.',
              )
            }
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function setActiveFile(file) {
  return { type: SET_ACTIVE_FILE, file }
}

export function deleteProject(projectId) {
  return (dispatch) => {
    const options = {
      method: 'DELETE',
      credentials: 'same-origin',
    }
    return fetch(`/api/project/jcm/${projectId}`, options)
      .then(
        handleAPIResponse(
          () => dispatch(fetchProjects()),
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function checkJCMProjectName(name) {
  return (dispatch) => {
    const query = { name }
    const options = { credentials: 'same-origin' }
    return fetch(
      '/api/project/jcm/check/project' + url.format({ query }),
      options,
    )
      .then(
        handleAPIResponse(
          () => {
            // do nothing unless validation fails
          },
          (json) => {
            const validation_errors = getFormValidationErrors(json)
            if (validation_errors) {
              // Use PassthroughContentsError so that handleAppError throws the
              // contents
              throw new PassthroughContentsError(validation_errors)
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function checkShapes(shapes) {
  return (dispatch) => {
    const query = { data: JSON.stringify(shapes) }
    const options = { credentials: 'same-origin' }
    return fetch(
      '/api/project/jcm/check/shape' + url.format({ query }),
      options,
    )
      .then(
        handleAPIResponse(
          (json) => {
            return json
          },
          () => {},
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function checkOneShape(shape) {
  return async (dispatch) => {
    const valid = await dispatch(checkShapes([shape]))
    if (valid && valid.length === 1) {
      return valid[0].valid
    }
  }
}

export function checkOrganisationValid(name, short_code) {
  return (dispatch) => {
    const query = { name, short_code }
    const options = { credentials: 'same-origin' }
    return fetch(
      '/api/project/jcm/check/organisation' + url.format({ query }),
      options,
    )
      .then(
        handleAPIResponse(
          () => {
            // do nothing unless validation fails
          },
          (json) => {
            const validation_errors = getFormValidationErrors(json)
            if (validation_errors) {
              // Use PassthroughContentsError so that handleAppError throws the
              // contents
              throw new PassthroughContentsError(validation_errors)
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function getEntityMatchProgress(state) {
  const formValues = getFormValues(MATCH_ENTITIES_FORM)(state)

  let matched = 0
  let total = 0

  if (formValues) {
    matched = _.reduce(
      formValues,
      (matched, value) => {
        if (!_.isEmpty(value)) {
          matched += 1
        }
        return matched
      },
      matched,
    )
  }

  const { unmatchedStates, unmatchedOrgs } = state.project.project.entities

  if (!_.isEmpty(unmatchedStates)) {
    total += unmatchedStates.length
  }

  if (!_.isEmpty(unmatchedOrgs)) {
    total += unmatchedOrgs.length
  }

  return { matched, total }
}

export function setJcmProcessStarted() {
  return { type: JCM_PROCESS_STARTED }
}

export function setJcmProcessStopped() {
  return { type: JCM_PROCESS_STOPPED }
}

export function setJcmProcessingMonitorUrl(monitorUrl) {
  return { type: SET_JCM_PROCESS_MONITOR_URL, monitorUrl }
}

export function processJCMProject(projectId) {
  return (dispatch) => {
    const options = { method: 'POST', credentials: 'same-origin' }
    return fetch(`/api/project/jcm/${projectId}/process`, options)
      .then(
        handleAPIResponse(
          (json) => {
            if ('monitor_url' in json) {
              dispatch(setJcmProcessStarted())
              dispatch(setJcmProcessingMonitorUrl(json.monitor_url))
              dispatch(updateJcmProcessStatus())
              dispatch(clearProcessVersionTooOld())
            } else {
              throw new Error('Malformed JSON response missing monitor_url.')
            }
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function setJcmProcessingStatus(status) {
  return { type: SET_JCM_PROCESS_STATUS, status }
}

export function updateJcmProcessStatus() {
  return (dispatch, getState) => {
    const state = getState()
    const monitorUrl = state.project.project.processingMonitorUrl
    const status = state.project.project.processingStatus

    if (status && status.state === 'SUCCESS') {
      return
    }

    if (monitorUrl == null) {
      return
    }

    const options = { credentials: 'same-origin' }
    return fetch(monitorUrl, options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(setJcmProcessingStatus(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function setJcmInsertStarted() {
  return { type: JCM_INSERT_STARTED }
}

export function setJcmInsertStopped() {
  return { type: JCM_INSERT_STOPPED }
}

export function setJcmInsertingMonitorUrl(monitorUrl) {
  return { type: SET_JCM_INSERT_MONITOR_URL, monitorUrl }
}

export function insertJCMProject(projectId) {
  return (dispatch) => {
    const options = { method: 'POST', credentials: 'same-origin' }
    return fetch(`/api/project/jcm/${projectId}/insert`, options)
      .then(
        handleAPIResponse(
          (json) => {
            if ('monitor_url' in json) {
              dispatch(setJcmInsertStarted())
              dispatch(setJcmInsertingMonitorUrl(json.monitor_url))
              dispatch(updateJcmInsertStatus())
            } else {
              throw new Error('Malformed JSON response missing monitor_url.')
            }
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function setJcmInsertingStatus(status) {
  return { type: SET_JCM_INSERT_STATUS, status }
}

export function updateJcmInsertStatus() {
  return (dispatch, getState) => {
    const state = getState()
    const monitorUrl = state.project.project.insertingMonitorUrl
    const status = state.project.project.insertingStatus

    if (status && ['SUCCESS', 'FAILURE'].includes(status.state)) {
      return
    }

    if (monitorUrl == null) {
      return
    }

    const options = { credentials: 'same-origin' }
    return fetch(monitorUrl, options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(setJcmInsertingStatus(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestTimestamps() {
  return { type: REQUEST_TIMESTAMPS }
}

export function receiveTimestamps(timestamps) {
  return { type: RECEIVE_TIMESTAMPS, timestamps }
}

export function fetchProjectTimestamps(projectId) {
  return (dispatch) => {
    dispatch(requestTimestamps())
    const options = { credentials: 'same-origin' }
    return fetch(`/api/project/jcm/${projectId}/timestamps`, options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveTimestamps(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function setCanAdvanceStep(canAdvanceStep) {
  return { type: SET_CAN_ADVANCE_STEP, canAdvanceStep }
}

export function requestFootnotes() {
  return { type: REQUEST_FOOTNOTES }
}

export function receiveFootnotes(footnotes = []) {
  return { type: RECEIVE_FOOTNOTES, footnotes }
}

export const canAdvanceStepSelector = (state) =>
  state.project.project.canAdvanceStep

export function fetchNewFootnotes(projectId) {
  return (dispatch) => {
    dispatch(requestFootnotes())
    const options = { credentials: 'same-origin' }
    return fetch(
      `/api/project/jcm/${projectId}/processed/new/footnotes`,
      options,
    )
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveFootnotes(json))
          },
          (json, error) => {
            if (error.response.status !== 404) {
              throw error
            } else {
              dispatch(receiveFootnotes())
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}
