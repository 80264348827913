import _ from 'lodash'

export function normalize(value) {
  if (value === '') {
    return []
  } else if (_.isString(value)) {
    return value.split(/,\s*/)
  }
  return value
}

export function format(value) {
  if (_.isArray(value)) {
    // Use trimEnd or the user can't backspace the space at the end because
    // it would reappear.
    return value.join(', ').trimEnd()
  }
  return value
}

const validDimensions = new Set(['length', 'height', 'depth', 'diameter'])

function isUnset(value) {
  return value == null || (_.isString(value) && value.trim() === '')
}

export function validate(value) {
  if (value == null) return
  for (const l of value) {
    if (!validDimensions.has(l)) {
      return 'must use these dimension names: length, height, depth, or diameter'
    }
  }
  if (value.length > 3) {
    return 'cannot contain more than 3 dimensions'
  }
  if (_.uniq(value).length !== value.length) {
    return 'cannot contain duplicates'
  }
}

export function validateDim(length) {
  return (value, values) => {
    if (
      !isUnset(value) &&
      (!_.isArray(values.dimension_map) || values.dimension_map.length < length)
    ) {
      return 'cannot be set without a corresponding Dimension Map'
    }
  }
}

export const validateDim1 = validateDim(1)
export const validateDim2 = validateDim(2)
export const validateDim3 = validateDim(3)
