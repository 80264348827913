import { handleAPIResponse } from '../util'

import { handleAppError } from './error'

export const REQUEST_ORGANISATIONS =
  'insertion/organisations/REQUEST_ORGANISATIONS'
export const RECEIVE_ORGANISATIONS = 'insertion/error/RECEIVE_ORGANISATIONS'

export const initialState = {
  organisations: [],
  isLoading: false,
}

// Reducer
export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_ORGANISATIONS:
      return { ...state, isLoading: true }
    case RECEIVE_ORGANISATIONS:
      return { ...state, isLoading: false, organisations: action.organisations }
    default:
      return state
  }
}

// Action Creators

export function requestOrganisations() {
  return { type: REQUEST_ORGANISATIONS }
}

export function receiveOrganisations(organisations) {
  return { type: RECEIVE_ORGANISATIONS, organisations }
}

export function fetchOrganisations() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestOrganisations())
    return fetch('/api/organisations', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveOrganisations(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export const organisationsSelector = (state) =>
  state.organisations.organisations
export const organisationsLoadingSelector = (state) =>
  state.organisations.isLoading
