import { connect } from 'react-redux'

import AddJCM from '../components/add-jcm'
import { handleAppError } from '../ducks/error'

const mapDispatchToProps = {
  handleAppError,
}

export default connect(null, mapDispatchToProps)(AddJCM)
