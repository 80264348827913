import { SubmissionError } from 'redux-form'

import { APIError, PassthroughContentsError } from '../error'

export const APP_ERROR = 'insertion/error/APP_ERROR'
export const APP_ERROR_DISMISS = 'insertion/error/APP_ERROR_DISMISS'
export const API_ERROR = 'insertion/error/API_ERROR'
export const API_ERROR_DISMISS = 'insertion/error/API_ERROR_DISMISS'

const initialState = {
  appError: null,
  apiError: null,
}

// Reducer
export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case APP_ERROR:
      return { ...state, appError: action.message }
    case APP_ERROR_DISMISS:
      return { ...state, appError: null }
    case API_ERROR:
      return { ...state, apiError: action.message }
    case API_ERROR_DISMISS:
      return { ...state, apiError: null }
    default:
      return state
  }
}

// Action Creators

export function setAppError(message) {
  return { type: APP_ERROR, message }
}

export function dismissAppError() {
  return { type: APP_ERROR_DISMISS }
}

export function setAPIError(message) {
  return { type: API_ERROR, message }
}

export function dismissAPIError() {
  return { type: API_ERROR_DISMISS }
}

/**
 * Can be passed to .catch after handleAppResponse to dispatch errors to the
 * app error handler (displays as alert component). SubmissionError's for
 * redux-form are re-thrown.
 * @param error
 */
export function handleAppError(error) {
  return async (dispatch) => {
    let message
    if (error instanceof APIError) {
      const response = error.response
      let json

      try {
        json = await response.clone().json()
      } catch (e) {
        const text = await response.text()

        message =
          'An error response was received during API ' +
          'communication, but without a valid JSON payload.\n\n' +
          `Error: ${response.status} ${response.statusText}\n\n` +
          `Response:\n${text.substring(0, 256)}`

        dispatch(setAPIError(message))

        if (process.env.NODE_ENV === 'development') {
          throw error
        }
        return
      }

      if (
        response.clone().statusText === 'UNAUTHORIZED' &&
        json.authenticated === false
      ) {
        window.location.reload()
      } else {
        message =
          'An error response was received during API communication.' +
          `\n\nError: ${response.status} ${response.statusText}\n\n` +
          `Response:\n${JSON.stringify(json, null, 4)}`

        dispatch(setAPIError(message))
        if (process.env.NODE_ENV === 'development') {
          throw error
        }
      }
    } else if (error instanceof SubmissionError) {
      // SubmissionError is re-thrown to be caught by redux-form
      throw error
    } else if (error instanceof PassthroughContentsError) {
      throw error.contents
    } else {
      message = 'The application experienced an error.\n\n' + `${error}`
      dispatch(setAppError(message))
      if (process.env.NODE_ENV === 'development') {
        throw error
      }
    }
  }
}
