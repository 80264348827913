import { PassthroughContentsError } from '../error'
import { getFormValidationErrors, handleAPIResponse } from '../util'

import { handleAppError } from './error'

const prefix = 'insertion/names'

export const REQUEST_NAME_SUBSTITUTIONS = `${prefix}/REQUEST_NAME_SUBSTITUTIONS`
export const RECEIVE_NAME_SUBSTITUTIONS = `${prefix}/RECEIVE_NAME_SUBSTITUTIONS`
export const SHOW_NEW_SUBSTITUTION_MODAL = `${prefix}/SHOW_NEW_SUBSTITUTION_MODAL`
export const HIDE_NEW_SUBSTITUTION_MODAL = `${prefix}/HIDE_NEW_SUBSTITUTION_MODAL`
export const SHOW_EDIT_SUBSTITUTION_MODAL = `${prefix}/SHOW_EDIT_SUBSTITUTION_MODAL`

export const initialState = {
  substitutions: [],
  subsLoading: false,
  showNewModal: false,
  editSubstitutionData: null,
}

// Reducer
export default function namesReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_NAME_SUBSTITUTIONS:
      return { ...state, subsLoading: true }
    case RECEIVE_NAME_SUBSTITUTIONS:
      return {
        ...state,
        subsLoading: false,
        substitutions: action.substitutions,
      }
    case SHOW_NEW_SUBSTITUTION_MODAL:
      return { ...state, showNewModal: true, editSubstitutionData: null }
    case SHOW_EDIT_SUBSTITUTION_MODAL:
      return { ...state, showNewModal: true, editSubstitutionData: action.data }
    case HIDE_NEW_SUBSTITUTION_MODAL:
      return { ...state, showNewModal: false }
    default:
      return state
  }
}

// Action Creators

export function requestNameSubstitutions() {
  return { type: REQUEST_NAME_SUBSTITUTIONS }
}

export function receiveNameSubstitutions(substitutions) {
  return { type: RECEIVE_NAME_SUBSTITUTIONS, substitutions }
}

export function fetchNameSubstitutions() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestNameSubstitutions())
    return fetch('/api/name_substitutions', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveNameSubstitutions(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function deleteNameSubstitution(id) {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
      method: 'DELETE',
    }
    const deleteUrl = '/api/name_substitutions'
    return fetch(deleteUrl, options)
      .then(
        handleAPIResponse(
          () => dispatch(fetchNameSubstitutions()),
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function showNewSubstitutionModal() {
  return { type: SHOW_NEW_SUBSTITUTION_MODAL }
}

export function showEditSubstitutionModal(data) {
  return { type: SHOW_EDIT_SUBSTITUTION_MODAL, data }
}

export function hideNewSubstitutionModal() {
  return { type: HIDE_NEW_SUBSTITUTION_MODAL }
}

export function validateSubstitution(values) {
  return (dispatch) => {
    const { search, replace, regex } = values
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        search: search || '',
        replace: replace || '',
        regex: regex || null,
      }),
      method: 'POST',
    }
    const checkUrl = '/api/project/jcm/check/name_substitution'
    return fetch(checkUrl, options)
      .then(
        handleAPIResponse(
          () => {
            // do nothing unless validation fails
          },
          (json) => {
            const validation_errors = getFormValidationErrors(json)
            if (validation_errors) {
              // Use PassthroughContentsError so that handleAppError throws the
              // contents
              throw new PassthroughContentsError(validation_errors)
            } else {
              throw json.error
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}
