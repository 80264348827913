import * as t from 'io-ts'

export function optional<C extends t.Mixed>(
  codec: C,
): t.UnionC<[C, t.UndefinedC]> {
  return t.union([codec, t.undefined])
}

export function nullable<C extends t.Mixed>(codec: C): t.UnionC<[C, t.NullC]> {
  return t.union([codec, t.null])
}
