import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Fragment } from 'react'
import {
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Glyphicon,
  HelpBlock,
  InputGroup,
} from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { fieldPropTypes, formValueSelector } from 'redux-form'

import { ABS_OBJ_FORM } from '../forms'
import { defaultDimensionMapForShape } from '../util'

import { normalize as normalizeDimensionMap } from './dimension-map'

const abstractObjectFormSelector = formValueSelector(ABS_OBJ_FORM)

const Input = (props) => {
  let validationState
  let helpBlock
  let feedback
  const smLabel = 3

  const {
    helpText,
    horizontal,
    input,
    label,
    meta: { visited, touched, error, asyncValidating, active },
    showAsyncValidation,
    untouchedError,
    showActiveValidation,
    showValid,
    type,
  } = props

  if (helpText) {
    helpBlock = <HelpBlock>{helpText}</HelpBlock>
  }

  let labelNode

  if (label) {
    if (horizontal) {
      labelNode = (
        <Col componentClass={ControlLabel} sm={smLabel}>
          {label}
        </Col>
      )
    } else {
      labelNode = <ControlLabel>{label}</ControlLabel>
    }
  }

  // sometimes we want to show an error while a field is active, but touched
  // is only set during onBlur. We can't rely solely on visited because only
  // touched is set during submit, which allows all errors to be shown.
  let touchedOrVisited = touched || visited

  if (
    (touchedOrVisited || (showAsyncValidation && untouchedError)) &&
    error &&
    (!active || showActiveValidation || showAsyncValidation)
  ) {
    validationState = 'error'

    // let's only show error message when field isn't active, otherwise it
    // appears and disappears when async validation is loading.
    // If field doesn't have async validation, show it always.
    if (!active || !showAsyncValidation) {
      if (label) {
        helpBlock = <HelpBlock>{`${label} ${error}`}</HelpBlock>
      } else {
        helpBlock = <HelpBlock>{error}</HelpBlock>
      }
    }
  } else if (
    touchedOrVisited &&
    !error &&
    !asyncValidating &&
    (!active || showActiveValidation || showAsyncValidation) &&
    showValid
  ) {
    validationState = 'success'
  }

  if (!asyncValidating && !showAsyncValidation) {
    feedback = <FormControl.Feedback />
  }

  const formControl = <FormControl type={type} {...input} />
  let spinnerFormControl

  if (showAsyncValidation) {
    let icon

    if (asyncValidating) {
      icon = <FontAwesomeIcon icon="spinner" pulse />
    } else if (error && (touchedOrVisited || untouchedError)) {
      icon = <Glyphicon glyph="remove" />
    } else {
      icon = <Glyphicon glyph="ok" />
    }

    spinnerFormControl = (
      <InputGroup>
        {formControl}
        <InputGroup.Addon>{icon}</InputGroup.Addon>
      </InputGroup>
    )
  } else {
    spinnerFormControl = formControl
  }

  let wrapped

  if (horizontal) {
    const colProps = {}

    if (!label) {
      colProps.smOffset = smLabel
    }

    wrapped = (
      <Col sm={12 - smLabel} {...colProps}>
        {spinnerFormControl}
        {feedback}
        {helpBlock}
      </Col>
    )
  } else {
    wrapped = (
      <Fragment>
        {spinnerFormControl}
        {feedback}
        {helpBlock}
      </Fragment>
    )
  }

  return (
    <FormGroup validationState={validationState}>
      {labelNode}
      {wrapped}
    </FormGroup>
  )
}

Input.propTypes = {
  // Passed to Field by user
  ...fieldPropTypes,
  helpText: PropTypes.node,
  label: PropTypes.string,
  showAsyncValidation: PropTypes.bool.isRequired,
  horizontal: PropTypes.bool.isRequired,
  untouchedError: PropTypes.bool.isRequired,
}

Input.defaultProps = {
  type: 'text',
  showValid: false,
  showActiveValidation: false,
  showAsyncValidation: false,
  horizontal: false,
  untouchedError: false,
}

export default Input

export function ShapeInput(props) {
  let helpText
  if (!props.meta.error && props.input.value) {
    const dimMap = defaultDimensionMapForShape(props.input.value).join(', ')
    helpText = `The default dimension map for this shape is ${dimMap}`
  }
  return <Input {...props} helpText={helpText} />
}

ShapeInput.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  showAsyncValidation: PropTypes.bool.isRequired,
  horizontal: PropTypes.bool.isRequired,
  untouchedError: PropTypes.bool.isRequired,
}

export function DimensionMapInput(props) {
  const shape = useSelector((state) =>
    abstractObjectFormSelector(state, 'shape'),
  )
  const dimensionMap = normalizeDimensionMap(props.input.value)
  const defaultDimensionMap = shape
    ? defaultDimensionMapForShape(shape)
    : undefined

  let helpText =
    'Changing the dimension map will change the meaning of any ' +
    'overridden per-object properties!'
  if (
    defaultDimensionMap !== undefined &&
    !_.isEqual(dimensionMap, defaultDimensionMap)
  ) {
    helpText = (
      <>
        <p>{helpText}</p>
        <p className="text-warning">
          {'Dimension map does not match default dimension map for this ' +
            `shape (${defaultDimensionMap.join(', ')})`}
        </p>
      </>
    )
  }

  return <Input {...props} helpText={helpText} />
}

DimensionMapInput.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  showAsyncValidation: PropTypes.bool.isRequired,
  horizontal: PropTypes.bool.isRequired,
  untouchedError: PropTypes.bool.isRequired,
}
