import PropTypes from 'prop-types'
import { Component } from 'react'
import { Alert, Button } from 'react-bootstrap'

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })
    console.error(error)
    console.error(info.componentStack)
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert bsStyle="danger">
          <h4>Component Error</h4>
          <p>Something went wrong while rendering this component.</p>
          <p>
            <Button bsStyle="danger" onClick={() => window.location.reload()}>
              Reload page
            </Button>
          </p>
        </Alert>
      )
    }

    return this.props.children
  }
}
