import { path } from 'static-path'

import { urlWithQuery } from './util'

const data = path('/data')
const objects = data.path('/objects')
export const objectsBrowser = objects.path('/browser')
export const objectPathEditor = objects.path('/paths')
export const organisations = data.path('/organisations')
export const vehicles = data.path('/vehicles')
export const newVehicle = vehicles.path('/new')
export const activityStatus = data.path('/activity-status')

export const dataValidation = data.path('/validation')
export const changelog = path('/changelog')
export const login = path('/login')
export const project = path('/project')
const jcm = project.path('/jcm')
export const nameSubs = project.path('/namesubs')
export const vehicleSubs = project.path('/vehiclesubs')
export const newJcm = jcm.path('/new')
export const jcmProject = jcm.path('/:projectId')
export const jcmProjectValidate = jcmProject.path('/validate')
export const jcmProjectFilter = jcmProject.path('/filter')
export const jcmProjectSites = jcmProject.path('/sites')
export const jcmProjectEntities = jcmProject.path('/entities')
export const jcmProjectVehicles = jcmProject.path('/vehicles')
export const jcmProjectProcess = jcmProject.path('/process')
export const jcmProjectFiles = jcmProject.path('/files')
export const jcmProjectStages = jcmProject.path('/stages')
export const oauth2Login = path('/auth/login')
export const oauth2Callback = path('/auth/callback')

interface ObjectsBrowserParams {
  name?: string
  discosId?: string | number
  satno?: string | number
  cosparId?: string
  jmdId?: string
  absObjId?: string | number
  type?: string
  matchesOnly?: boolean
}

/**
 * A type-safe generator for params supported by the object browser
 */
export function objectBrowserWithQuery(params: ObjectsBrowserParams): string {
  const searchParams = new URLSearchParams()
  for (const [key, value] of Object.entries(params)) {
    if (value != null) {
      searchParams.set(key, value.toString())
    }
  }
  return urlWithQuery(objectsBrowser({}), searchParams)
}

interface ObjectPathEditorParams {
  discosId?: number | number[]
}

export function objectPathEditorWithQuery({
  discosId: discosIds,
}: ObjectPathEditorParams): string {
  const searchParams = new URLSearchParams()
  if (discosIds !== undefined) {
    if (Array.isArray(discosIds)) {
      for (const discosId of discosIds) {
        searchParams.append('discosId', discosId.toString())
      }
    } else {
      searchParams.set('discosId', discosIds.toString())
    }
  }
  return urlWithQuery(objectPathEditor({}), searchParams)
}
