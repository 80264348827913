/* global PRELOADED_STATE:false */
import { configureStore } from '@reduxjs/toolkit'
import { reducer as formReducer } from 'redux-form'

import authReducer from './auth'
import devTools from './devTools'
import errorReducer from './error'
import namesReducer from './names'
import objectsReducer from './objects'
import organisationsReducer from './organisations'
import parserReducer from './parser'
import processReducer from './process'
import projectReducer from './project'
import sitesReducer from './sites'
import vehiclesReducer from './vehicles'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    error: errorReducer,
    form: formReducer,
    names: namesReducer,
    objects: objectsReducer,
    organisations: organisationsReducer,
    parser: parserReducer,
    process: processReducer,
    project: projectReducer,
    sites: sitesReducer,
    vehicles: vehiclesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
  devTools,
  preloadedState: PRELOADED_STATE,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
