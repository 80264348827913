import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import 'core-js'
import { createRoot } from 'react-dom/client'
import 'regenerator-runtime/runtime'

import { store } from './ducks'
import createRoutes from './routes'
import './styles/antd.less'
import './styles/main.scss'

library.add(fas)

export { store }
const routes = createRoutes(store)

if (process.env.NODE_ENV !== 'testing') {
  const container = document.getElementById('app')
  const root = createRoot(container)
  root.render(routes)
}
