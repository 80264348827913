import _ from 'lodash'
import { useRef } from 'react'
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'
import { useMemoOne } from 'use-memo-one'

import type { AppDispatch, RootState } from './ducks'

export function useLazyInit<T>(creator: () => T): T {
  const ref = useRef<T | null>(null)
  if (ref.current === null) {
    ref.current = creator()
  }
  return ref.current
}

export function useUniqueId(prefix = ''): string {
  return useLazyInit(() => _.uniqueId(prefix))
}

export type KeyedCallback<K, A extends unknown[], R> = (key: K, ...args: A) => R

/**
 * Debounce a callback based on the first argument. That is, each different
 * value of the first argument creates a custom debounced function. The
 * callback should
 *
 * @example
 *
 *   const save = useDebouncedCallbackKeyed(
 *     useCallback(
 *       (value: string): void => {
 *         // POST request or something
 *       },
 *       [<deps>],
 *     ),
 *     300
 *   )
 */
export function useDebouncedCallbackKeyed<K, A extends unknown[], R>(
  callback: KeyedCallback<K, A, R>,
  wait?: number,
  { leading, maxWait, trailing }: _.DebounceSettings = {},
): KeyedCallback<K, A, R | undefined> {
  return useMemoOne(() => {
    const functions = new Map<K, _.DebouncedFunc<KeyedCallback<K, A, R>>>()
    const options: _.DebounceSettings = {}
    if (leading !== undefined) options.leading = leading
    if (maxWait !== undefined) options.maxWait = maxWait
    if (trailing !== undefined) options.trailing = trailing

    return (key, ...args) => {
      let debounced = functions.get(key)
      if (debounced === undefined) {
        debounced = _.debounce(callback, wait, options)
        functions.set(key, debounced)
      }

      return debounced(key, ...args)
    }
  }, [callback, wait, leading, maxWait, trailing])
}

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
