import _ from 'lodash'
import invariant from 'tiny-invariant'

import type {
  CategoryDiffItem,
  DiffCategory,
  DiffField,
  DiffItem,
  JmdManoeuvreCapability,
} from './codecs'

export enum FieldType {
  String,
  StringArray,
  Number,
  EntityArray,
  DiscosObject,
  Failure,
  LaunchSite,
  Vehicle,
  Frame,
  DimensionMap,
  AbstractObject,
  JmdManoeuvreCapability,
}

export interface FieldTypeTypes {
  [FieldType.String]: string
  [FieldType.StringArray]: string[]
  [FieldType.Number]: number
  [FieldType.EntityArray]: number[]
  [FieldType.DiscosObject]: number
  [FieldType.AbstractObject]: number
  [FieldType.Failure]: boolean
  [FieldType.LaunchSite]: number
  [FieldType.Vehicle]: number
  [FieldType.Frame]: number
  [FieldType.DimensionMap]: string[]
  [FieldType.JmdManoeuvreCapability]: JmdManoeuvreCapability
}

export type DiffFieldType<C extends DiffCategory, F extends CategoryFields<C>> =
  CategoryDiffItem<C>['diff'][F] extends DiffField<infer T> ? T : never

export type CategoryFieldTypes = {
  [C in Extract<DiffCategory, string>]: {
    [F in Extract<CategoryFields<C>, string>]: FieldType
  }
}

export type DiffItemFields<D extends DiffItem> = keyof D['diff']

export type CategoryFields<C extends DiffCategory> = DiffItemFields<
  CategoryDiffItem<C>
>

const fieldTypes: CategoryFieldTypes = {
  objects: {
    abs_obj_type: FieldType.String,
    abs_obj_id: FieldType.AbstractObject,
    abs_obj_name: FieldType.String,
    abs_obj_ascii_name: FieldType.String,
    abs_obj_alt_names: FieldType.StringArray,
    abs_obj_native_name: FieldType.String,
    obj_name: FieldType.String,
    obj_ascii_name: FieldType.String,
    obj_alt_names: FieldType.StringArray,
    obj_native_name: FieldType.String,
    shape: FieldType.String,
    height: FieldType.Number,
    length: FieldType.Number,
    depth: FieldType.Number,
    diameter: FieldType.Number,
    dimension_map: FieldType.DimensionMap,
    span: FieldType.Number,
    wet_mass: FieldType.Number,
    dry_mass: FieldType.Number,
    mission: FieldType.String,
    states: FieldType.EntityArray,
    manufacturers: FieldType.EntityArray,
    operators: FieldType.EntityArray,
    mro_from_id: FieldType.DiscosObject,
    debris_from_id: FieldType.DiscosObject,
    fragmented_from_id: FieldType.DiscosObject,
    bus_type: FieldType.String,
    manoeuvrability: FieldType.JmdManoeuvreCapability,
  },
  initial_orbits: {
    epoch: FieldType.String,
    inc: FieldType.Number,
    a_per: FieldType.Number,
    ecc: FieldType.Number,
    sma: FieldType.Number,
    frame_id: FieldType.Frame,
  },
  reentries: {
    epoch: FieldType.String,
  },
  launches: {
    epoch: FieldType.String,
    flight_no: FieldType.String,
    failure: FieldType.Failure,
    site_id: FieldType.LaunchSite,
    vehicle_node_id: FieldType.Vehicle,
  },
  // field types not used for footnotes, but we need this to satisfy current
  // types.
  footnotes: {
    note: FieldType.String,
  },
}

/**
 * Get the FieldType of a given category and field.
 *
 * This function exists because TypeScript can't type-check the lookup
 * for some reason.
 */
export function getFieldType<
  C extends DiffCategory,
  F extends CategoryFields<C> extends never ? string : CategoryFields<C>,
>(category: C, field: F): FieldType {
  const f = field as unknown as keyof CategoryFieldTypes[C]
  return fieldTypes[category][f] as unknown as FieldType
}

export type CategoryFieldNames = {
  [C in DiffCategory]: {
    [F in keyof CategoryDiffItem<C>['diff']]?: string
  }
}

const fieldNames: CategoryFieldNames = {
  objects: {
    abs_obj_name: 'Abstract Object Name',
    abs_obj_ascii_name: 'Abstract Object ASCII Name',
    abs_obj_alt_names: 'Abstract Object Alt. Names',
    abs_obj_native_name: 'Abstract Object Native Name',
    obj_name: 'Object Name',
    obj_ascii_name: 'Object ASCII Name',
    obj_alt_names: 'Object Alt. Names',
    obj_native_name: 'Object Native Name',
  },
  initial_orbits: {
    inc: 'Inclination (deg)',
    a_per: 'Argument of Periapsis (deg)',
    ecc: 'Eccentricity',
    sma: 'Semi-major Axis (m)',
    frame_id: 'Frame',
  },
  reentries: {},
  launches: {
    flight_no: 'Flight No.',
    epoch: 'Epoch',
    failure: 'Failure?',
    site_id: 'Launch Site',
    vehicle_node_id: 'Launch Vehicle',
  },
  footnotes: {}, // footnotes not used here as it has a custom component
}

/**
 * Get the field name of a given category and field.
 *
 * This function exists because TypeScript can't type-check the lookup
 * for some reason.
 */
export function getFieldName<
  C extends DiffCategory,
  F extends CategoryFields<C> extends never ? string : CategoryFields<C>,
>(category: C, field: F): string {
  const f = field as unknown as keyof CategoryFieldNames[C]
  const fieldName = fieldNames[category][f] as unknown as string | undefined
  return fieldName ?? (field as string)
}

export interface FieldOption {
  key: string
  label: string
}

export const getFieldOptions = _.memoize(
  (category: DiffCategory): FieldOption[] => {
    return Object.keys(fieldTypes[category]).map((key) => ({
      key,
      label: getFieldName(category, key),
    }))
  },
)

export function getDiffFields(diffItem: DiffItem): Array<DiffField<unknown>> {
  return Object.values(diffItem.diff) as Array<DiffField<unknown>>
}

export function getDiffEntries(
  diffItem: DiffItem,
): Array<[string, DiffField<unknown>]> {
  return Object.entries(diffItem.diff) as Array<[string, DiffField<unknown>]>
}

export function getDiffField(
  diffItem: DiffItem,
  field: string,
): DiffField<unknown> {
  // @ts-ignore
  const diffField = diffItem.diff[field] as DiffField<unknown>
  invariant(diffField !== undefined)
  return diffField as DiffField<unknown>
}
