import { Suspense, lazy } from 'react'

import { ComponentLoading } from '../components/loading'

const LazyJCMProjectConnector = lazy(() => import('./jcm-project-connector'))
export const JCMProjectConnector = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyJCMProjectConnector {...props} />
  </Suspense>
)

const LazyObjectsConnector = lazy(() => import('./objects-connector'))
export const ObjectsConnector = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyObjectsConnector {...props} />
  </Suspense>
)

const LazyVehiclesConnector = lazy(() => import('./vehicles-connector'))
export const VehiclesConnector = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyVehiclesConnector {...props} />
  </Suspense>
)

const LazyNameSubsConnector = lazy(() => import('./name-subs-connector'))
export const NameSubsConnector = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyNameSubsConnector {...props} />
  </Suspense>
)

const LazyVehicleSubsConnector = lazy(() => import('./vehicle-subs-connector'))
export const VehicleSubsConnector = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyVehicleSubsConnector {...props} />
  </Suspense>
)

const LazyOrganisationsConnector = lazy(
  () => import('../components/organisations'),
)
export const OrganisationsConnector = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyOrganisationsConnector {...props} />
  </Suspense>
)

const LazyAddVehicleConnector = lazy(() => import('./add-vehicle-connector'))
export const AddVehicleConnector = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyAddVehicleConnector {...props} />
  </Suspense>
)

const LazyObjectPaths = lazy(() => import('../components/object-paths'))
export const ObjectPaths = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyObjectPaths {...props} />
  </Suspense>
)

const LazyChangelog = lazy(() => import('../components/changelog'))
export const Changelog = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyChangelog {...props} />
  </Suspense>
)

const LazyActivityStatus = lazy(() => import('../components/activity-status'))
export const ActivityStatus = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyActivityStatus {...props} />
  </Suspense>
)

const LazyDataValidation = lazy(() => import('../components/data-validation'))
export const DataValidation = (props) => (
  <Suspense fallback={<ComponentLoading />}>
    <LazyDataValidation {...props} />
  </Suspense>
)
