import { createAsyncThunk, createReducer } from '@reduxjs/toolkit'

import { handleAPIResponse } from '../util'

import { handleAppError } from './error'
import type { AppDispatch, RootState } from './index'

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
}>()

interface ParserState {
  typeFlag: string[]
  typeFlagLoading: boolean
}

const initialState: ParserState = {
  typeFlag: [],
  typeFlagLoading: false,
}

export const fetchTypeFlag = createAppAsyncThunk(
  'parser/fetchTypeFlag',
  async (_, { dispatch }) => {
    const response = await fetch('/api/jcm/flags/type')
    const handle = handleAPIResponse(
      (json) => json,
      (_, error) => {
        dispatch(handleAppError(error))
      },
    )
    return await handle(response)
  },
  {
    condition: (_, { getState }) => {
      const state = getState()
      if (state.parser.typeFlag.length > 0 || state.parser.typeFlagLoading) {
        return false
      }
      return undefined
    },
  },
)

export default createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTypeFlag.pending, (state) => {
      state.typeFlagLoading = true
    })
    .addCase(fetchTypeFlag.fulfilled, (state, action) => {
      state.typeFlag = action.payload
      state.typeFlagLoading = false
    })
})

export const typeFlagSelector = (state: RootState): string[] =>
  state.parser.typeFlag
export const typeFlagLoadingSelector = (state: RootState): boolean =>
  state.parser.typeFlagLoading
