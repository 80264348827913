import type { ConfigureStoreOptions } from '@reduxjs/toolkit'
import { produce } from 'immer'
import { actionTypes as rfActionTypes } from 'redux-form'

type DevToolsOptions = ConfigureStoreOptions['devTools']

function sanitizeFileData(fileData: any): void {
  for (const key of Object.keys(fileData)) {
    fileData[key].data = '<<large blob>>'
  }
}

const options: DevToolsOptions = {
  actionsDenylist: [
    rfActionTypes.REGISTER_FIELD,
    rfActionTypes.UNREGISTER_FIELD,
  ],
  stateSanitizer: (state: any): any =>
    produce(state, (draft: any) => {
      sanitizeFileData(draft.project.project.validation.fileData)
    }),
  actionSanitizer: (action) =>
    produce(action, (draft) => {
      if ('fileData' in draft) {
        sanitizeFileData(draft.fileData)
      }
    }),
}

export default options
