import bytes from 'bytes'
import PropTypes from 'prop-types'
import { Component } from 'react'
import {
  Button,
  ControlLabel,
  FormGroup,
  Glyphicon,
  HelpBlock,
} from 'react-bootstrap'
import Dropzone from 'react-dropzone'
import { Field, reduxForm } from 'redux-form'

import * as paths from '../paths'
import { asyncValidateJCMProjectName } from '../project'
import {
  handleAPIResponse,
  handleFormSubmissionError,
  iconForFile,
} from '../util'

import Input from './input'

const validateForm = (values) => {
  const errors = {}
  if (!values.name) {
    errors.name = 'This field is required.'
  }
  if (!values.files) {
    errors.files = 'File upload is required.'
  }

  return errors
}

const renderFileUploads = (field) => {
  const files = field.input.value
  let attachedFileBlock

  const { touched, error } = field.meta

  if (files) {
    const attachedFiles = files.map((file, i) => (
      <li key={i}>
        {iconForFile(file, { fixedWidth: true, className: 'fa-li' })}&nbsp;
        {file.name} ({bytes(file.size, { unitSeparator: ' ' })})
      </li>
    ))

    attachedFileBlock = (
      <ul className="attached-file-group fa-ul">{attachedFiles}</ul>
    )
  }

  let errorBlock
  let validationState

  if (touched && error) {
    validationState = 'error'
    errorBlock = (
      <HelpBlock>
        <strong>{error}</strong>
      </HelpBlock>
    )
  }

  return (
    <FormGroup validationState={validationState}>
      <ControlLabel>Files</ControlLabel>
      <div className="dropzone-container">
        <Dropzone
          name={field.name}
          onDrop={(files) => field.input.onChange(files)}
          ref={(c) => field.setDropzone(c)}
          noClick
          maxSize={50 * Math.pow(1024, 2)}
        >
          {({ getInputProps, getRootProps }) => (
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              Drag and drop files here.
            </div>
          )}
        </Dropzone>
        <Button bsStyle="success" onClick={field.openDropzone}>
          Choose <Glyphicon glyph="folder-open" />
        </Button>
      </div>
      {attachedFileBlock}
      <HelpBlock>
        Please upload the container file (typically named <code>esa.tgz</code>)
        or the relevant files within (<code>log.esa.*</code>). Max size 50 MB.
      </HelpBlock>
      {errorBlock}
    </FormGroup>
  )
}

class AddJCM extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    handleAppError: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    const body = new FormData()
    Object.keys(data).forEach((key) => {
      if (key !== 'files') {
        body.append(key, data[key])
      }
    })

    const files = data['files']
    if (files) {
      files.forEach((file) => {
        body.append('file[]', file)
      })
    }

    const options = {
      credentials: 'same-origin',
      method: 'POST',
      body,
    }

    return fetch('/api/project/jcm/new', options)
      .then(
        handleAPIResponse((json) => {
          this.props.history.push(
            paths.jcmProject({ projectId: json.project_id.toString() }),
          )
        }, handleFormSubmissionError),
      )
      .catch(this.props.handleAppError)
  }

  setDropzone = (c) => {
    this.dropzone = c
  }

  openDropzone = () => {
    this.dropzone.open()
  }

  render() {
    const submitting = this.props.submitting

    return (
      <div>
        <h1>
          <center>Start new JMD insertion</center>
        </h1>

        <form
          className="form-size"
          onSubmit={this.props.handleSubmit(this.onSubmit)}
        >
          <Field
            name="name"
            component={Input}
            showAsyncValidation
            showValid
            untouchedError // may need removed, this was added when changing defaults
          />
          <Field
            name="files"
            component={renderFileUploads}
            setDropzone={this.setDropzone}
            openDropzone={this.openDropzone}
          />
          <Button type="submit" bsStyle="primary" disabled={submitting}>
            Submit
          </Button>
        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: 'jcm-start',
  validate: validateForm,
  asyncValidate: asyncValidateJCMProjectName,
  asyncBlurFields: ['name'],
})(AddJCM)
