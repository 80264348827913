import _ from 'lodash'
import { createSelector } from 'reselect'
import url from 'url'

import { arrayToIdObj, handleAPIResponse } from '../util'

import { handleAppError } from './error'

const prefix = 'insertion/objects'

export const RECEIVE_OBJECTS = `${prefix}/RECEIVE_OBJECTS`
export const REQUEST_RESOLVED_OBJECTS = `${prefix}/REQUEST_RESOLVED_OBJECTS`
export const RECEIVE_RESOLVED_OBJECTS = `${prefix}/RECEIVE_RESOLVED_OBJECTS`
export const RECEIVE_ABSTRACT_OBJECTS = `${prefix}/RECEIVE_ABSTRACT_OBJECTS`
export const REQUEST_MULTI_ABSTRACT_OBJECTS = `${prefix}/REQUEST_MULTI_ABSTRACT_OBJECTS`
export const RECEIVE_MULTI_ABSTRACT_OBJECTS = `${prefix}/RECEIVE_MULTI_ABSTRACT_OBJECTS`

export const initialState = {
  // these objects will have the object-specific names, rather than falling back
  // to the abstract object names.
  objects: {},
  abstractObjects: {},
  // resolved objects are those from the objects_view table, which includes
  // the name of the abstract object if the object doesn't have its own name.
  resolvedObjects: {},
  resolvedObjectsLoading: false,
  // multiAbstractObjects are a list of abstract objects with the number of
  // objects assigned to them. They are also sorted from most objects to least.
  /** @type any */
  multiAbstractObjects: {
    byId: {},
    allIds: [],
  },
  multiAbstractObjectsLoading: false,
}

// Reducer
export default function objectsReducer(state = initialState, action) {
  let objects
  let abstractObjects
  let resolvedObjects

  switch (action.type) {
    case RECEIVE_OBJECTS:
      objects = { ...state.objects, ..._.keyBy(action.objects, 'discos_id') }
      return { ...state, objects }
    case RECEIVE_ABSTRACT_OBJECTS:
      abstractObjects = {
        ...state.abstractObjects,
        ..._.keyBy(action.abstractObjects, 'id'),
      }
      return { ...state, abstractObjects }
    case REQUEST_RESOLVED_OBJECTS:
      return { ...state, resolvedObjectsLoading: true }
    case RECEIVE_RESOLVED_OBJECTS:
      resolvedObjects = {
        ...state.resolvedObjects,
        ..._.keyBy(action.resolvedObjects, 'discos_id'),
      }
      return { ...state, resolvedObjects, resolvedObjectsLoading: false }
    case REQUEST_MULTI_ABSTRACT_OBJECTS:
      return { ...state, multiAbstractObjectsLoading: true }
    case RECEIVE_MULTI_ABSTRACT_OBJECTS:
      return {
        ...state,
        multiAbstractObjects: arrayToIdObj(action.multiAbstractObjects),
        multiAbstractObjectsLoading: false,
      }
    default:
      return state
  }
}

// Action Creators

export function receiveObjects(objects) {
  return { type: RECEIVE_OBJECTS, objects }
}

export function fetchObjects(discosIds) {
  return async (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    if (discosIds.length === 0) {
      dispatch(receiveObjects([]))
      return
    }

    const handle = handleAPIResponse(
      (json) => json,
      (json, error) => {
        throw error
      },
    )

    async function fetchBatch(discosIds) {
      const query = { discosId: discosIds }
      const apiUrl = '/api/objects' + url.format({ query })
      const response = await fetch(apiUrl, options)
      return await handle(response)
    }

    let results
    try {
      results = await Promise.all(_.chunk(discosIds, 250).map(fetchBatch))
    } catch (err) {
      await dispatch(handleAppError(err))
    }

    if (results !== undefined) {
      dispatch(receiveObjects(_.flatten(results)))
    }
  }
}

export function receiveAbstractObjects(abstractObjects) {
  return { type: RECEIVE_ABSTRACT_OBJECTS, abstractObjects }
}

export function fetchAbstractObjects(ids) {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    if (ids.length === 0) {
      dispatch(receiveAbstractObjects([]))
      return
    }

    const query = { id: ids }

    return fetch('/api/abstract_objects' + url.format({ query }), options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveAbstractObjects(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function receiveResolvedObjects(resolvedObjects) {
  return { type: RECEIVE_RESOLVED_OBJECTS, resolvedObjects }
}

export function requestResolvedObjects() {
  return { type: REQUEST_RESOLVED_OBJECTS }
}

export function fetchResolvedObjects(discosIds, ifRequired = false) {
  return (dispatch, getState) => {
    if (ifRequired) {
      const state = getState()
      const resolvedObjects = getResolvedObjects(state)
      const isLoading = getResolvedObjectsLoading(state)
      if (isLoading || !_.isEmpty(resolvedObjects)) return
    }
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    const query = {}

    if (discosIds) {
      query.discosId = discosIds
    }

    dispatch(requestResolvedObjects())
    return fetch('/api/objects/resolved' + url.format({ query }), options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveResolvedObjects(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export const getResolvedObjects = (state) => state.objects.resolvedObjects
export const getResolvedObjectsLoading = (state) =>
  state.objects.resolvedObjectsLoading

export function receiveMultiAbstractObjects(multiAbstractObjects) {
  return { type: RECEIVE_MULTI_ABSTRACT_OBJECTS, multiAbstractObjects }
}

export function requestMultiAbstractObjects() {
  return { type: REQUEST_MULTI_ABSTRACT_OBJECTS }
}

export function fetchMultiAbstractObjects() {
  return (dispatch, getState) => {
    const state = getState()
    const resolvedObjects = getMultiAbstractObjects(state)
    const isLoading = getMultiAbstractObjectsLoading(state)
    if (isLoading || !_.isEmpty(resolvedObjects)) return
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    dispatch(requestMultiAbstractObjects())
    return fetch('/api/multi_abstract_objects', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveMultiAbstractObjects(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export const getMultiAbstractObjectsById = (state) =>
  state.objects.multiAbstractObjects.byId
export const getMultiAbstractObjectsAllIds = (state) =>
  state.objects.multiAbstractObjects.allIds
export const getMultiAbstractObjectsLoading = (state) =>
  state.objects.multiAbstractObjectsLoading

export const getMultiAbstractObjects = createSelector(
  getMultiAbstractObjectsById,
  getMultiAbstractObjectsAllIds,
  (byId, allIds) => allIds.map((id) => byId[id]),
)
