import { connect } from 'react-redux'

import Dashboard from '../components/dashboard'
import {
  deleteProject,
  fetchProjects,
  hideDeleteModal,
  showDeleteModal,
} from '../ducks/project'

const mapStateToProps = (state) => ({
  projects: state.project.projectList.projects,
  deleteModalData: state.project.projectList.deleteModal,
})

const mapDispatchToProps = {
  fetchProjects,
  showDeleteModal,
  hideDeleteModal,
  deleteProject,
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
