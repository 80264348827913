import { ThreeBounce } from 'better-react-spinkit'
import PropTypes from 'prop-types'
import { Alert, Button, ButtonToolbar } from 'react-bootstrap'

export const Loading = () => (
  <div className="text-center">
    <ThreeBounce />
  </div>
)

/**
 * Used by our Loadable component
 */
export const ComponentLoading = ({ pastDelay, error, retry }) => {
  if (error) {
    // This console.error is important! Without it, we don't see the error
    // that occurred in the console.
    //
    console.error(error)

    return (
      <Alert bsStyle="danger">
        <h4>Unexpected Error</h4>
        <p>There was an error loading part of the application.</p>
        <p>If it was a problem with the network, you may be able to retry.</p>
        <ButtonToolbar style={{ marginTop: '10px' }}>
          <Button bsStyle="warning" onClick={retry}>
            Retry
          </Button>
          <Button bsStyle="danger" onClick={() => window.location.reload()}>
            Reload page
          </Button>
        </ButtonToolbar>
      </Alert>
    )
  } else if (pastDelay) {
    return (
      <div className="text-center">
        <ThreeBounce />
      </div>
    )
  } else {
    return null
  }
}

ComponentLoading.propTypes = {
  pastDelay: PropTypes.bool.isRequired,
  error: PropTypes.any,
  retry: PropTypes.func,
}

ComponentLoading.defaultProps = {
  pastDelay: true,
}
