import moment from 'moment'
import url from 'url'

import { PassthroughContentsError } from '../error'
import {
  arrayToIdObj,
  camelizeDeep,
  getFormValidationErrors,
  handleAPIResponse,
} from '../util'

import { handleAppError } from './error'
import { setShouldParse } from './project'

const prefix = 'insertion/vehicles'

export const REQUEST_VEHICLE_SUBSTITUTIONS = `${prefix}/REQUEST_VEHICLE_SUBSTITUTIONS`
export const RECEIVE_VEHICLE_SUBSTITUTIONS = `${prefix}/RECEIVE_VEHICLE_SUBSTITUTIONS`
export const SHOW_NEW_SUBSTITUTION_MODAL = `${prefix}/SHOW_NEW_SUBSTITUTION_MODAL`
export const HIDE_NEW_SUBSTITUTION_MODAL = `${prefix}/HIDE_NEW_SUBSTITUTION_MODAL`
export const SHOW_EDIT_SUBSTITUTION_MODAL = `${prefix}/SHOW_EDIT_SUBSTITUTION_MODAL`
export const REQUEST_VEHICLES = `${prefix}/REQUEST_VEHICLES`
export const RECEIVE_VEHICLES = `${prefix}/RECEIVE_VEHICLES`
export const RECEIVE_VEHICLE_UPDATES = `${prefix}/RECEIVE_VEHICLE_UPDATES`
export const SELECT_ABSTRACT_OBJECT = `${prefix}/SELECT_ABSTRACT_OBJECT`
export const REQUEST_SYSTEM_FAMILIES = `${prefix}/REQUEST_SYSTEM_FAMILIES`
export const RECEIVE_SYSTEM_FAMILIES = `${prefix}/RECEIVE_SYSTEM_FAMILIES`
export const REQUEST_NEW_VEHICLE_TREE = `${prefix}/REQUEST_NEW_VEHICLE_TREE`
export const RECEIVE_NEW_VEHICLE_TREE = `${prefix}/RECEIVE_NEW_VEHICLE_TREE`
export const SAVE_NEW_VEHICLE_TREE = `${prefix}/SAVE_NEW_VEHICLE_TREE`
export const RESET_NEW_VEHICLE_TREE = `${prefix}/RESET_NEW_VEHICLE_TREE`
export const SAVED_NEW_VEHICLE_TREE = `${prefix}/SAVED_NEW_VEHICLE_TREE`
export const SET_STEP = `${prefix}/SET_STEP`
export const REQUEST_FUELS = `${prefix}/REQUEST_FUELS`
export const RECEIVE_FUELS = `${prefix}/RECEIVE_FUELS`
export const REQUEST_OXIDISERS = `${prefix}/REQUEST_OXIDISERS`
export const RECEIVE_OXIDISERS = `${prefix}/RECEIVE_OXIDISERS`
export const REQUEST_PRESSURANTS = `${prefix}/REQUEST_PRESSURANTS`
export const RECEIVE_PRESSURANTS = `${prefix}/RECEIVE_PRESSURANTS`
export const REQUEST_SOLID_PROPELLANTS = `${prefix}/REQUEST_SOLID_PROPELLANTS`
export const RECEIVE_SOLID_PROPELLANTS = `${prefix}/RECEIVE_SOLID_PROPELLANTS`
export const REQUEST_STAGE_FAMILIES = `${prefix}/REQUEST_STAGE_FAMILIES`
export const RECEIVE_STAGE_FAMILIES = `${prefix}/RECEIVE_STAGE_FAMILIES`
export const REQUEST_STAGE_TYPES = `${prefix}/REQUEST_STAGE_TYPES`
export const RECEIVE_STAGE_TYPES = `${prefix}/RECEIVE_STAGE_TYPES`
export const REQUEST_ENGINE_FAMILIES = `${prefix}/REQUEST_ENGINE_FAMILIES`
export const RECEIVE_ENGINE_FAMILIES = `${prefix}/RECEIVE_ENGINE_FAMILIES`
export const REQUEST_PROJECT_VEHICLES = `${prefix}/REQUEST_PROJECT_VEHICLES`
export const RECEIVE_PROJECT_VEHICLES = `${prefix}/RECEIVE_PROJECT_VEHICLES`
export const SET_JCM_VEHICLES_INITIAL_VALUES = `${prefix}/SET_JCM_VEHICLES_INITIAL_VALUES`
export const SET_JCM_STAGES_INITIAL_VALUES = `${prefix}/SET_JCM_STAGES_INITIAL_VALUES`
export const REQUEST_SIMPLE_VEHICLES = `${prefix}/REQUEST_SIMPLE_VEHICLES`
export const RECEIVE_SIMPLE_VEHICLES = `${prefix}/RECEIVE_SIMPLE_VEHICLES`
export const REQUEST_WEB_DATA = `${prefix}/REQUEST_WEB_DATA`
export const RECEIVE_WEB_DATA = `${prefix}/RECEIVE_WEB_DATA`
export const REQUEST_STAGE_VEHICLES = `${prefix}/REQUEST_STAGE_VEHICLES`
export const RECEIVE_STAGE_VEHICLES = `${prefix}/RECEIVE_STAGE_VEHICLES`
export const REQUEST_VEHICLE_STAGES = `${prefix}/REQUEST_VEHICLE_STAGES`
export const RECEIVE_VEHICLE_STAGES = `${prefix}/RECEIVE_VEHICLE_STAGES`

export const initialState = {
  substitutions: [],
  subsLoading: false,
  showNewSubsModal: false,
  editSubstitutionData: null,
  vehiclesLoading: false,
  vehicleTree: [],
  vehicleMaxSeenId: null,
  jcmVehiclesInitialValues: {},
  jcmVehiclesMatchProgress: { total: 0, matched: 0 },
  jcmStages: {
    initialValues: {},
    matchProgress: { total: 0, matched: 0 },
  },
  abstractObjects: [],
  selectedNodeKey: null,
  launchSystemFamiliesTree: [],
  systemFamiliesLoading: false,
  newVehicleTree: null,
  newVehicleImages: null,
  newVehicleDocs: null,
  savingNewVehicle: false,
  loadingNewVehicle: false,
  step: null,
  fuels: [],
  fuelsLoading: false,
  oxidisers: [],
  oxidisersLoading: false,
  pressurants: [],
  pressurantsLoading: false,
  solidPropellants: [],
  solidPropellantsLoading: false,
  stageFamilies: [],
  stageFamiliesLoading: false,
  stageTypes: [],
  stageTypesLoading: false,
  engineFamilies: [],
  engineFamiliesLoading: false,
  vehicleLaunchesLoading: false,
  vehicleLaunches: {
    byId: {},
    allIds: [],
    guessIds: [],
    existingIds: [],
    savedIds: [],
    unmatchedIds: [],
  },
  simpleVehiclesLoading: false,
  simpleVehicles: {
    byId: {},
    allIds: [],
  },
  webDataLoading: false,
  webData: {},
  stageVehicles: {
    byId: {},
    allIds: [],
    savedIds: [],
    unmatchedIds: [],
  },
  stageVehiclesLoading: false,
  vehicleStages: {
    byId: {},
    allIds: [],
  },
  vehicleStagesLoading: false,
}

// Reducer
export default function vehiclesReducer(state = initialState, action) {
  let absObjMap
  let abstractObjects

  switch (action.type) {
    case REQUEST_VEHICLE_SUBSTITUTIONS:
      return { ...state, subsLoading: true }
    case RECEIVE_VEHICLE_SUBSTITUTIONS:
      return {
        ...state,
        subsLoading: false,
        substitutions: action.substitutions,
      }
    case SHOW_NEW_SUBSTITUTION_MODAL:
      return { ...state, showNewSubsModal: true, editSubstitutionData: null }
    case SHOW_EDIT_SUBSTITUTION_MODAL:
      return {
        ...state,
        showNewSubsModal: true,
        editSubstitutionData: action.data,
      }
    case HIDE_NEW_SUBSTITUTION_MODAL:
      return { ...state, showNewSubsModal: false }
    case REQUEST_VEHICLES:
      return { ...state, vehiclesLoading: true }
    case RECEIVE_VEHICLES:
      return {
        ...state,
        vehiclesLoading: false,
        vehicleTree: action.tree,
        abstractObjects: action.abstractObjects,
        vehicleMaxSeenId: action.maxSeenId,
      }
    case RECEIVE_VEHICLE_UPDATES:
      absObjMap = state.abstractObjects.reduce((obj, absObj) => {
        obj[absObj.id] = absObj
        return obj
      }, {})

      abstractObjects = [...state.abstractObjects]

      action.abstractObjects.forEach((absObj) => {
        if (absObjMap[absObj.id] == null) {
          abstractObjects.push(absObj)
        }
      })

      return {
        ...state,
        vehiclesLoading: false,
        vehicleTree: state.vehicleTree.concat(action.tree),
        abstractObjects: abstractObjects,
        vehicleMaxSeenId: action.maxSeenId,
      }
    case SET_JCM_VEHICLES_INITIAL_VALUES:
      return {
        ...state,
        jcmVehiclesInitialValues: action.initialValues,
        jcmVehiclesMatchProgress: action.vehicleMatchProgress,
      }
    case SET_JCM_STAGES_INITIAL_VALUES:
      return {
        ...state,
        jcmStages: {
          initialValues: action.initialValues,
          matchProgress: action.matchProgress,
        },
      }
    case SELECT_ABSTRACT_OBJECT:
      return { ...state, selectedNodeKey: action.nodeKey }
    case REQUEST_SYSTEM_FAMILIES:
      return { ...state, systemFamiliesLoading: true }
    case RECEIVE_SYSTEM_FAMILIES:
      return {
        ...state,
        systemFamiliesLoading: false,
        launchSystemFamiliesTree: action.tree,
      }
    case SAVE_NEW_VEHICLE_TREE:
      return {
        ...state,
        savingNewVehicle: true,
        newVehicleTree: action.tree,
        newVehicleImages: action.imagesFileData,
        newVehicleDocs: action.docsFileData,
      }
    case SAVED_NEW_VEHICLE_TREE:
      return { ...state, savingNewVehicle: false }
    case REQUEST_NEW_VEHICLE_TREE:
      return { ...state, loadingNewVehicle: true }
    case RECEIVE_NEW_VEHICLE_TREE:
      return {
        ...state,
        loadingNewVehicle: false,
        newVehicleTree: action.tree,
        newVehicleImages: action.imagesFileData,
        newVehicleDocs: action.docsFileData,
      }
    case RESET_NEW_VEHICLE_TREE:
      return {
        ...state,
        newVehicleTree: null,
        newVehicleImages: null,
        newVehicleDocs: null,
      }
    case SET_STEP:
      return { ...state, step: action.step }
    case REQUEST_FUELS:
      return { ...state, fuelsLoading: true }
    case RECEIVE_FUELS:
      return { ...state, fuelsLoading: false, fuels: action.data }
    case REQUEST_OXIDISERS:
      return { ...state, oxidisersLoading: true }
    case RECEIVE_OXIDISERS:
      return { ...state, oxidisersLoading: false, oxidisers: action.data }
    case REQUEST_PRESSURANTS:
      return { ...state, pressurantsLoading: true }
    case RECEIVE_PRESSURANTS:
      return { ...state, pressurantsLoading: false, pressurants: action.data }
    case REQUEST_SOLID_PROPELLANTS:
      return { ...state, solidPropellantsLoading: true }
    case RECEIVE_SOLID_PROPELLANTS:
      return {
        ...state,
        solidPropellantsLoading: false,
        solidPropellants: action.data,
      }
    case REQUEST_STAGE_FAMILIES:
      return { ...state, stageFamiliesLoading: true }
    case RECEIVE_STAGE_FAMILIES:
      return {
        ...state,
        stageFamiliesLoading: false,
        stageFamilies: action.data,
      }
    case REQUEST_STAGE_TYPES:
      return { ...state, stageTypesLoading: true }
    case RECEIVE_STAGE_TYPES:
      return { ...state, stageTypesLoading: false, stageTypes: action.data }
    case REQUEST_ENGINE_FAMILIES:
      return { ...state, engineFamiliesLoading: true }
    case RECEIVE_ENGINE_FAMILIES:
      return {
        ...state,
        engineFamiliesLoading: false,
        engineFamilies: action.data,
      }
    case REQUEST_PROJECT_VEHICLES:
      return { ...state, vehicleLaunchesLoading: true }
    case RECEIVE_PROJECT_VEHICLES:
      return {
        ...state,
        vehicleLaunchesLoading: false,
        vehicleLaunches: action.vehicleLaunches,
      }
    case REQUEST_SIMPLE_VEHICLES:
      return { ...state, simpleVehiclesLoading: true }
    case RECEIVE_SIMPLE_VEHICLES:
      return {
        ...state,
        simpleVehiclesLoading: false,
        simpleVehicles: action.simpleVehicles,
      }
    case REQUEST_WEB_DATA:
      return { ...state, webDataLoading: true }
    case RECEIVE_WEB_DATA:
      return { ...state, webDataLoading: false, webData: action.webData }
    case REQUEST_STAGE_VEHICLES:
      return { ...state, stageVehiclesLoading: true }
    case RECEIVE_STAGE_VEHICLES:
      return {
        ...state,
        stageVehiclesLoading: false,
        stageVehicles: action.stageVehicles,
      }
    case REQUEST_VEHICLE_STAGES:
      return { ...state, vehicleStagesLoading: true }
    case RECEIVE_VEHICLE_STAGES:
      return {
        ...state,
        vehicleStagesLoading: false,
        vehicleStages: action.vehicleStages,
      }
    default:
      return state
  }
}

// Action Creators

export function requestVehicleSubstitutions() {
  return { type: REQUEST_VEHICLE_SUBSTITUTIONS }
}

export function receiveVehicleSubstitutions(substitutions) {
  return { type: RECEIVE_VEHICLE_SUBSTITUTIONS, substitutions }
}

export function fetchVehicleSubstitutions() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestVehicleSubstitutions())
    return fetch('/api/vehicle_substitutions', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveVehicleSubstitutions(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function deleteVehicleSubstitution(id) {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ id }),
      method: 'DELETE',
    }
    const deleteUrl = '/api/vehicle_substitutions'
    return fetch(deleteUrl, options)
      .then(
        handleAPIResponse(
          () => {
            dispatch(fetchVehicleSubstitutions())
          },
          (json) => {
            throw json
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function showNewSubstitutionModal() {
  return { type: SHOW_NEW_SUBSTITUTION_MODAL }
}

export function showEditSubstitutionModal(data) {
  return { type: SHOW_EDIT_SUBSTITUTION_MODAL, data }
}

export function hideNewSubstitutionModal() {
  return { type: HIDE_NEW_SUBSTITUTION_MODAL }
}

export function validateSubstitution(values) {
  return (dispatch) => {
    const { search, replace, regex } = values
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        search: search || '',
        replace: replace || '',
        regex: regex || null,
      }),
      method: 'POST',
    }
    const checkUrl = '/api/project/jcm/check/vehicle_substitution'
    return fetch(checkUrl, options)
      .then(
        handleAPIResponse(
          () => {
            // do nothing unless validation fails
          },
          (json) => {
            const validation_errors = getFormValidationErrors(json)
            if (validation_errors) {
              // Use PassthroughContentsError so that handleAppError throws the
              // contents
              throw new PassthroughContentsError(validation_errors)
            } else {
              throw json.error
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestVehicles() {
  return { type: REQUEST_VEHICLES }
}

export function receiveVehicles(tree, abstractObjects, maxSeenId) {
  return { type: RECEIVE_VEHICLES, tree, abstractObjects, maxSeenId }
}

export function receiveVehicleUpdates(tree, abstractObjects, maxSeenId) {
  return { type: RECEIVE_VEHICLE_UPDATES, tree, abstractObjects, maxSeenId }
}

export function fetchVehicles() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestVehicles())
    return fetch('/api/launch_vehicles', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(
              receiveVehicles(
                json.tree,
                json.abstract_objects,
                json.max_seen_id,
              ),
            )
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestSimpleVehicles() {
  return { type: REQUEST_SIMPLE_VEHICLES }
}

export function receiveSimpleVehicles(simpleVehicles) {
  return { type: RECEIVE_SIMPLE_VEHICLES, simpleVehicles }
}

export function fetchSimpleVehicles() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestSimpleVehicles())
    return fetch('/api/simple_launch_vehicles', options)
      .then(
        handleAPIResponse(
          (json) => {
            const data = arrayToIdObj(camelizeDeep(json), 'nodeId')
            dispatch(receiveSimpleVehicles(data))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export const simpleVehiclesSelector = (state) => state.vehicles.simpleVehicles
export const simpleVehiclesLoadingSelector = (state) =>
  state.vehicles.simpleVehiclesLoading

export function updateVehicles() {
  return (dispatch, getState) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    const vehicleMaxSeenId = getState().vehicles.vehicleMaxSeenId

    let query = {}
    if (vehicleMaxSeenId != null) {
      query.max_seen_id = vehicleMaxSeenId
    }
    const fetchUrl = '/api/launch_vehicles'
    return fetch(fetchUrl + url.format({ query }), options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(
              receiveVehicleUpdates(
                json.tree,
                json.abstract_objects,
                json.max_seen_id,
              ),
            )
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function selectAbstractObject(nodeKey) {
  return { type: SELECT_ABSTRACT_OBJECT, nodeKey }
}

export function requestLaunchSystemFamilies() {
  return { type: REQUEST_SYSTEM_FAMILIES }
}

export function receiveLaunchSystemFamilies(tree) {
  return { type: RECEIVE_SYSTEM_FAMILIES, tree }
}

export function fetchLaunchSystemFamilies() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestLaunchSystemFamilies())
    return fetch('/api/launch_system_families', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveLaunchSystemFamilies(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export const launchSystemFamiliesTreeSelector = (state) =>
  state.vehicles.launchSystemFamiliesTree
export const launchSystemFamiliesLoadingSelector = (state) =>
  state.vehicles.systemFamiliesLoading

export function saveNewVehicleTree(tree, imagesFileData, docsFileData) {
  return { type: SAVE_NEW_VEHICLE_TREE, tree, imagesFileData, docsFileData }
}

export function savedNewVehicleTree() {
  return { type: SAVED_NEW_VEHICLE_TREE }
}

export function updateNewVehicleTree(tree, imagesFileData, docsFileData) {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        tree,
        images: imagesFileData,
        docs: docsFileData,
      }),
    }

    dispatch(saveNewVehicleTree(tree, imagesFileData, docsFileData))
    return fetch('/api/launch_vehicle_tree', options)
      .then(
        handleAPIResponse(
          () => {
            dispatch(savedNewVehicleTree())
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestNewVehicleTree() {
  return { type: REQUEST_NEW_VEHICLE_TREE }
}

export function receiveNewVehicleTree(tree, imagesFileData, docsFileData) {
  return { type: RECEIVE_NEW_VEHICLE_TREE, tree, imagesFileData, docsFileData }
}

export function resetNewVehicleTree() {
  return { type: RESET_NEW_VEHICLE_TREE }
}

export function fetchNewVehicleTree() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    dispatch(requestNewVehicleTree())
    return fetch('/api/launch_vehicle_tree', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveNewVehicleTree(json.tree, json.images, json.docs))
          },
          (json, error) => {
            if (error.response.status !== 404) {
              throw error
            } else {
              dispatch(receiveNewVehicleTree(null, null, null))
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function setStep(step) {
  return { type: SET_STEP, step }
}

export function requestFuels() {
  return { type: REQUEST_FUELS }
}

export function receiveFuels(data) {
  return { type: RECEIVE_FUELS, data }
}

export function fetchFuels() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestFuels())
    return fetch('/api/fuels', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveFuels(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestOxidisers() {
  return { type: REQUEST_OXIDISERS }
}

export function receiveOxidisers(data) {
  return { type: RECEIVE_OXIDISERS, data }
}

export function fetchOxidisers() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestOxidisers())
    return fetch('/api/oxidisers', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveOxidisers(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestPressurants() {
  return { type: REQUEST_PRESSURANTS }
}

export function receivePressurants(data) {
  return { type: RECEIVE_PRESSURANTS, data }
}

export function fetchPressurants() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestPressurants())
    return fetch('/api/pressurants', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receivePressurants(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestSolidPropellants() {
  return { type: REQUEST_SOLID_PROPELLANTS }
}

export function receiveSolidPropellants(data) {
  return { type: RECEIVE_SOLID_PROPELLANTS, data }
}

export function fetchSolidPropellants() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestSolidPropellants())
    return fetch('/api/solid_propellants', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveSolidPropellants(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestStageFamilies() {
  return { type: REQUEST_STAGE_FAMILIES }
}

export function receiveStageFamilies(data) {
  return { type: RECEIVE_STAGE_FAMILIES, data }
}

export function fetchStageFamilies() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestStageFamilies())
    return fetch('/api/stage_families', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveStageFamilies(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestEngineFamilies() {
  return { type: REQUEST_ENGINE_FAMILIES }
}

export function receiveEngineFamilies(data) {
  return { type: RECEIVE_ENGINE_FAMILIES, data }
}

export function fetchEngineFamilies() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestEngineFamilies())
    return fetch('/api/engine_families', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveEngineFamilies(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestStageTypes() {
  return { type: REQUEST_STAGE_TYPES }
}

export function receiveStageTypes(data) {
  return { type: RECEIVE_STAGE_TYPES, data }
}

export function fetchStageTypes() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestStageTypes())
    return fetch('/api/stage_types', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveStageTypes(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestProjectVehicles() {
  return { type: REQUEST_PROJECT_VEHICLES }
}

export function receiveProjectVehicles(vehicleLaunches) {
  return { type: RECEIVE_PROJECT_VEHICLES, vehicleLaunches }
}

export function fetchProjectVehicles(projectId) {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestProjectVehicles())

    const byId = {}
    const allIds = []
    const guessIds = []
    const existingIds = []
    const savedIds = []
    const unmatchedIds = []

    return fetch(`/api/project/jcm/${projectId}/vehicles`, options)
      .then(
        handleAPIResponse(
          (json) => {
            const data = camelizeDeep(json)
            const initialValues = {}

            // we have lots of aggregation to do, so do it all at once

            data.forEach((value) => {
              if (value.savedNodeId !== null) {
                savedIds.push(value.id)
                initialValues[value.id] = value.savedNodeId
              } else if (value.existingNodeId !== null) {
                existingIds.push(value.id)
                initialValues[value.id] = value.existingNodeId
              } else if (value.guessNodeId !== null) {
                guessIds.push(value.id)
                initialValues[value.id] = value.guessNodeId
              } else {
                unmatchedIds.push(value.id)
              }
              allIds.push(value.id)
              byId[value.id] = value

              value.launch.date = moment(value.launch.date)
            })

            dispatch(
              receiveProjectVehicles({
                byId,
                allIds,
                guessIds,
                existingIds,
                savedIds,
                unmatchedIds,
              }),
            )
            dispatch(
              setJcmVehiclesInitialValues(initialValues, {
                total: allIds.length,
                matched: savedIds.length,
              }),
            )
          },
          (json, error) => {
            if (json.should_parse) {
              dispatch(
                receiveProjectVehicles({
                  byId,
                  allIds,
                  guessIds,
                  existingIds,
                  savedIds,
                  unmatchedIds,
                }),
              )
              dispatch(setShouldParse())
            } else {
              throw error
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function setJcmVehiclesInitialValues(
  initialValues,
  vehicleMatchProgress,
) {
  return {
    type: SET_JCM_VEHICLES_INITIAL_VALUES,
    initialValues,
    vehicleMatchProgress,
  }
}

export const setJcmStagesInitialValues = (initialValues, matchProgress) => ({
  type: SET_JCM_STAGES_INITIAL_VALUES,
  initialValues,
  matchProgress,
})

export function requestWebData() {
  return { type: REQUEST_WEB_DATA }
}

export function receiveWebData(webData) {
  return { type: RECEIVE_WEB_DATA, webData }
}

export function fetchWebData(projectId) {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestWebData())
    return fetch(`/api/project/jcm/${projectId}/web/launches`, options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveWebData(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestStageVehicles() {
  return { type: REQUEST_STAGE_VEHICLES }
}

export function receiveStageVehicles(stageVehicles) {
  return { type: RECEIVE_STAGE_VEHICLES, stageVehicles }
}

/**
 * This fetches the vehicles for each object in the project that is a stage. It
 * takes into account the saved launch vehicles.
 * @param projectId
 * @returns {function(*)}
 */
export function fetchStageVehicles(projectId) {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    dispatch(requestStageVehicles())

    const byId = {}
    const allIds = []
    const savedIds = []
    const unmatchedIds = []

    return fetch(`/api/project/jcm/${projectId}/stage_vehicles`, options)
      .then(
        handleAPIResponse(
          (json) => {
            const data = camelizeDeep(json)
            const initialValues = {}

            data.forEach((value) => {
              if (value.savedAbsObjId !== null) {
                savedIds.push(value.id)
                initialValues[value.id] = value.savedAbsObjId
              } else {
                unmatchedIds.push(value.id)
              }
              allIds.push(value.id)
              byId[value.id] = value
            })

            dispatch(
              receiveStageVehicles({
                byId,
                allIds,
                savedIds,
                unmatchedIds,
              }),
            )
            dispatch(
              setJcmStagesInitialValues(initialValues, {
                total: allIds.length,
                matched: savedIds.length,
              }),
            )
          },
          (json, error) => {
            if (json.should_parse) {
              dispatch(
                receiveStageVehicles({
                  byId,
                  allIds,
                  savedIds,
                  unmatchedIds,
                }),
              )
              dispatch(setShouldParse())
            } else {
              throw error
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestVehicleStages() {
  return { type: REQUEST_VEHICLE_STAGES }
}

export function receiveVehicleStages(vehicleStages) {
  return { type: RECEIVE_VEHICLE_STAGES, vehicleStages }
}

export function fetchVehicleStages() {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }

    dispatch(requestVehicleStages())
    return fetch('/api/vehicle_stages', options)
      .then(
        handleAPIResponse(
          (json) => {
            const data = arrayToIdObj(camelizeDeep(json), 'nodeId')
            dispatch(receiveVehicleStages(data))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}
