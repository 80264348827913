import _ from 'lodash'
import { getFormValues } from 'redux-form'
import { createSelector } from 'reselect'
import url from 'url'

import { PassthroughContentsError } from '../error'
import { MATCH_SITES_FORM } from '../forms'
import { getFormValidationErrors, handleAPIResponse } from '../util'

import { handleAppError } from './error'
import { setShouldParse } from './project'

const prefix = 'insertion/sites'

export const REQUEST_LAUNCH_SITES = `${prefix}/REQUEST_LAUNCH_SITES`
export const RECEIVE_LAUNCH_SITES = `${prefix}/RECEIVE_LAUNCH_SITES`
export const REQUEST_UNMATCHED_SITES = `${prefix}/REQUEST_UNMATCHED_SITES`
export const RECEIVE_UNMATCHED_SITES = `${prefix}/RECEIVE_UNMATCHED_SITES`
export const SHOW_NEW_SITE_MODAL = `${prefix}/SHOW_NEW_SITE_MODAL`
export const HIDE_NEW_SITE_MODAL = `${prefix}/HIDE_NEW_SITE_MODAL`

const initialState = {
  launchSites: [],
  unmatchedSites: [],
  sitesLoading: false,
  unmatchedLoading: false,
  showNewModal: false,
  inputNameForNew: null,
}

// Reducer
export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case REQUEST_LAUNCH_SITES:
      return { ...state, sitesLoading: true }
    case RECEIVE_LAUNCH_SITES:
      return { ...state, sitesLoading: false, launchSites: action.launchSites }
    case REQUEST_UNMATCHED_SITES:
      return { ...state, unmatchedLoading: true }
    case RECEIVE_UNMATCHED_SITES:
      return {
        ...state,
        unmatchedLoading: false,
        unmatchedSites: action.unmatchedSites,
      }
    case SHOW_NEW_SITE_MODAL:
      return { ...state, showNewModal: true, inputNameForNew: action.inputName }
    case HIDE_NEW_SITE_MODAL:
      return { ...state, showNewModal: false, inputNameForNew: null }
    default:
      return state
  }
}

// Action Creators

export function requestLaunchSites() {
  return { type: REQUEST_LAUNCH_SITES }
}

export function receiveLaunchSites(launchSites) {
  return { type: RECEIVE_LAUNCH_SITES, launchSites }
}

export function fetchLaunchSites(ifRequired = false) {
  return (dispatch, getState) => {
    if (ifRequired) {
      const state = getState()
      const sites = sitesSelector(state)
      const isLoading = sitesLoadingSelector(state)
      if (isLoading || !_.isEmpty(sites)) return
    }
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestLaunchSites())
    return fetch('/api/launch_sites', options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveLaunchSites(json))
          },
          (json, error) => {
            throw error
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function requestUnmatchedSites() {
  return { type: REQUEST_UNMATCHED_SITES }
}

export function receiveUnmatchedSites(unmatchedSites) {
  return { type: RECEIVE_UNMATCHED_SITES, unmatchedSites }
}

export function fetchUnmatchedSites(projectId) {
  return (dispatch) => {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
    dispatch(requestUnmatchedSites())
    return fetch(`/api/project/jcm/${projectId}/unmatched_sites`, options)
      .then(
        handleAPIResponse(
          (json) => {
            dispatch(receiveUnmatchedSites(json))
          },
          (json, error) => {
            if (json.should_parse) {
              dispatch(receiveUnmatchedSites([]))
              dispatch(setShouldParse())
            } else {
              throw error
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function showNewSiteModal(inputName) {
  return { type: SHOW_NEW_SITE_MODAL, inputName }
}

export function hideNewSiteModal(inputName) {
  return { type: HIDE_NEW_SITE_MODAL, inputName }
}

export function validateSite(values) {
  return (dispatch) => {
    const { name, site_code } = values
    const options = {
      credentials: 'same-origin',
    }
    const query = { name, site_code }
    const checkUrl = '/api/project/jcm/check/site'
    return fetch(checkUrl + url.format({ query }), options)
      .then(
        handleAPIResponse(
          () => {
            // do nothing unless validation fails
          },
          (json) => {
            const validation_errors = getFormValidationErrors(json)
            if (validation_errors) {
              // Use PassthroughContentsError so that handleAppError throws the
              // contents
              throw new PassthroughContentsError(validation_errors)
            } else {
              throw json.error
            }
          },
        ),
      )
      .catch((err) => dispatch(handleAppError(err)))
  }
}

export function getSiteMatchProgress(state) {
  const formValues = getFormValues(MATCH_SITES_FORM)(state)

  let matched = 0
  let total = 0

  if (formValues) {
    matched = _.reduce(
      formValues,
      (matched, value) => {
        if (!_.isEmpty(value)) {
          matched += 1
        }
        return matched
      },
      matched,
    )
  }

  const { unmatchedSites } = state.sites

  if (!_.isEmpty(unmatchedSites)) {
    total += unmatchedSites.length
  }

  return { matched, total }
}

// Selectors

export const sitesLoadingSelector = (state) => state.sites.sitesLoading

export const sitesSelector = (state) => state.sites.launchSites

export const sitesByIdSelector = createSelector(sitesSelector, (sites) =>
  _.keyBy(sites, 'id'),
)
