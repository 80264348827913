import type { Location } from 'history'
import { Button, Panel } from 'react-bootstrap'
import { useLocation } from 'react-router'

import * as paths from '../paths'

export default function Login(): JSX.Element {
  const nextLocation = useNextLocation()

  const sdoLoginUrl = new URL(paths.oauth2Login({}), window.location.href)
  if (nextLocation !== undefined) {
    sdoLoginUrl.searchParams.set(
      'next',
      nextLocation.pathname + nextLocation.search + nextLocation.hash,
    )
  }

  return (
    <div className="tw-mx-auto tw-max-w-lg tw-mt-40 tw-flex tw-justify-center">
      <Button bsStyle="success" href={sdoLoginUrl.toString()}>
        Sign in via Space Debris User Account
      </Button>
    </div>
  )
}

interface FromState {
  from?: Location
}

function useNextLocation(): Location | undefined {
  const location = useLocation()
  const state = location.state as FromState | undefined
  return state?.from
}

export function CallbackError(): JSX.Element {
  return (
    <div className="tw-flex tw-justify-center">
      <Panel bsStyle="danger" className="tw-max-w-3xl">
        <Panel.Heading>
          <Panel.Title componentClass="h3">Authentication Error</Panel.Title>
        </Panel.Heading>
        <Panel.Body>
          <p>
            There was a problem signing in. Please{' '}
            <a href={paths.login({})}>try again</a>.
          </p>
          <p>
            This may happen if your browser has cookies disabled. If this
            problem persists, please contact the DevOps team.
          </p>
        </Panel.Body>
      </Panel>
    </div>
  )
}
