import { Fragment } from 'react'
import { Alert, Button } from 'react-bootstrap'
import { Toaster } from 'react-hot-toast'
import { Route as ReactRouterRoute, Switch } from 'react-router'

import AddJCMConnector from '../containers/add-jcm-connector'
import {
  ActivityStatus,
  AddVehicleConnector,
  Changelog,
  DataValidation,
  JCMProjectConnector,
  NameSubsConnector,
  ObjectPaths,
  ObjectsConnector,
  OrganisationsConnector,
  VehicleSubsConnector,
  VehiclesConnector,
} from '../containers/async'
import DashboardConnector from '../containers/dashboard-connector'
import Route from '../containers/route'
import {
  dismissAPIError,
  dismissAppError,
  handleAppError,
} from '../ducks/error'
import { useAppDispatch, useAppSelector } from '../hooks'
import { usePageWidth } from '../page-width'
import * as paths from '../paths'
import { handleAPIResponse } from '../util'

import Header from './header'
import Login, { CallbackError } from './login'

interface ErrorPanelProps {
  message: string
  title: string
  onDismiss: () => void
}

function ErrorPanel(props: ErrorPanelProps): JSX.Element {
  return props.message ? (
    <Alert bsStyle="danger" onDismiss={props.onDismiss}>
      <h4>{props.title}</h4>
      <p style={{ whiteSpace: 'pre-wrap' }}>{props.message}</p>
      <p>
        <Button bsStyle="danger" onClick={reloadPage}>
          Reload page
        </Button>
        <span> or </span>
        <Button onClick={props.onDismiss}>Hide alert</Button>
      </p>
    </Alert>
  ) : (
    (null as unknown as JSX.Element)
  )
}

function reloadPage(): void {
  window.location.reload()
}

export default function App(): JSX.Element {
  const dispatch = useAppDispatch()
  const { fluid } = usePageWidth()

  async function handleLogout(): Promise<void> {
    const response = await fetch('/auth/logout', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
      },
    })
    const handle = handleAPIResponse(
      (json) => {
        window.location.href = json.redirect
      },
      (_, error) => {
        dispatch(handleAppError(error))
      },
    )
    await handle(response)
  }

  const username = useAppSelector((state) => state.auth.username)
  const appError = useAppSelector((state) => state.error.appError)
  const apiError = useAppSelector((state) => state.error.apiError)

  return (
    // wrap the whole thing in a <Route> or e.g. the link in <Header> won't
    // re-render the <Switch>
    <>
      <ReactRouterRoute>
        <Fragment>
          <Header
            username={username}
            handleLogout={handleLogout}
            fluid={fluid}
          />
          <div className={fluid ? 'container-fluid' : 'container'}>
            <ErrorPanel
              title="Application Error"
              message={appError}
              onDismiss={() => dispatch(dismissAppError())}
            />
            <ErrorPanel
              title="API Error"
              message={apiError}
              onDismiss={() => dispatch(dismissAPIError())}
            />
            <Switch>
              <Route
                exact
                path={paths.project.pattern}
                component={DashboardConnector}
              />
              <Route
                private={false}
                path={paths.login.pattern}
                component={Login}
              />
              <Route
                path={paths.newVehicle.pattern}
                component={AddVehicleConnector}
              />
              <Route
                private={false}
                path={paths.oauth2Callback.pattern}
                component={CallbackError}
              />
              <Route path={paths.newJcm.pattern} component={AddJCMConnector} />
              <Route
                path={paths.jcmProject.pattern}
                component={JCMProjectConnector}
              />
              <Route
                path={paths.organisations.pattern}
                component={OrganisationsConnector}
              />
              <Route
                path={paths.vehicles.pattern}
                component={VehiclesConnector}
              />
              <Route
                path={paths.activityStatus.pattern}
                component={ActivityStatus}
              />
              <Route
                path={paths.objectsBrowser.pattern}
                component={ObjectsConnector}
              />
              <Route
                path={paths.objectPathEditor.pattern}
                component={ObjectPaths}
              />
              <Route
                path={paths.nameSubs.pattern}
                component={NameSubsConnector}
              />
              <Route
                path={paths.vehicleSubs.pattern}
                component={VehicleSubsConnector}
              />
              <Route path={paths.changelog.pattern} component={Changelog} />
              <Route
                path={paths.dataValidation.pattern}
                component={DataValidation}
              />
            </Switch>
          </div>
        </Fragment>
      </ReactRouterRoute>
      <Toaster
        position="top-right"
        containerStyle={{ top: 'calc(50px + 16px)' }}
        toastOptions={{ style: { borderWidth: '1px' } }}
      />
    </>
  )
}
