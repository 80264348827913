import {
  Glyphicon,
  MenuItem,
  Nav,
  NavDropdown,
  NavItem,
  Navbar,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link, useLocation } from 'react-router-dom'

import * as paths from '../paths'

interface HeaderProps {
  username: string | null
  handleLogout: () => void
  fluid?: boolean
}

function Header(props: HeaderProps): JSX.Element {
  let loginState

  const usernameText = (
    <span>
      <Glyphicon glyph="user" /> {props.username}
    </span>
  )

  if (props.username) {
    loginState = (
      <Nav pullRight>
        <NavDropdown title={usernameText} id="account-dropdown">
          <MenuItem onClick={props.handleLogout}>
            <Glyphicon glyph="log-out" /> Sign out
          </MenuItem>
        </NavDropdown>
      </Nav>
    )
  } else {
    loginState = (
      <Nav pullRight>
        <LinkContainer to={paths.login({})}>
          <NavItem>
            <Glyphicon glyph="user" /> Sign in
          </NavItem>
        </LinkContainer>
      </Nav>
    )
  }

  const location = useLocation()
  const activeData = location.pathname.startsWith('/data/')
  const activeProject = new RegExp('/project(/|$)').test(location.pathname)

  return (
    <div>
      <Navbar staticTop fluid={props.fluid}>
        <Navbar.Header>
          <Navbar.Brand>
            <Link to={paths.project({})}>DISCOS</Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav>
            <NavDropdown
              title="Insertion"
              id="data-dropdown"
              active={activeProject}
            >
              <LinkContainer to={paths.project({})} exact>
                <NavItem>Projects</NavItem>
              </LinkContainer>
              <MenuItem divider />
              <LinkContainer to={paths.nameSubs({})}>
                <MenuItem>Name Substitutions</MenuItem>
              </LinkContainer>
              <LinkContainer to={paths.vehicleSubs({})}>
                <MenuItem>Vehicle Substitutions</MenuItem>
              </LinkContainer>
            </NavDropdown>
            <NavDropdown
              title="Data Editors"
              id="data-dropdown"
              active={activeData}
            >
              <LinkContainer to={paths.objectsBrowser({})}>
                <NavItem>Abstract Object Browser</NavItem>
              </LinkContainer>
              <LinkContainer to={paths.objectPathEditor({})}>
                <NavItem>Object Path Editor</NavItem>
              </LinkContainer>
              <MenuItem divider />
              <LinkContainer to={paths.organisations({})}>
                <NavItem>Organisations</NavItem>
              </LinkContainer>
              <LinkContainer to={paths.vehicles({})}>
                <NavItem>Launch Vehicles</NavItem>
              </LinkContainer>
              <LinkContainer to={paths.activityStatus({})}>
                <NavItem>Activity Status</NavItem>
              </LinkContainer>
              <LinkContainer to={paths.dataValidation({})}>
                <NavItem>Data Validation</NavItem>
              </LinkContainer>
            </NavDropdown>
            <LinkContainer to={paths.changelog({})}>
              <NavItem>Changelog</NavItem>
            </LinkContainer>
          </Nav>
          {loginState}
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Header
