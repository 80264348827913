import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import type { DateTime } from 'luxon'
import mimetype2fa from 'mimetype-to-fontawesome'
import { SubmissionError } from 'redux-form'
import url from 'url'

import { APIError } from './error'
import { DateTimeFromString, type Dictionary, parseCodec } from './types'

export function checkStatus(response: Response): Response {
  const clientError = 400 <= response.status && response.status < 500
  const serverError = 500 <= response.status && response.status < 600

  if (clientError || serverError) {
    throw new APIError(response.statusText, response)
  } else {
    return response
  }
}

/**
 *  This function should be passed to .then() after fetch(). onSuccess will be
 *  called with JSON if the response code is ok. If the response code represents
 *  a client/server error, onFailure is called if (and only if) valid JSON was
 *  returned. Make sure to also use .catch() for other errors.
 * @param onSuccess - Called with (json)
 * @param onFailure - Called with (json, error)
 */
export function handleAPIResponse<S, F>(
  onSuccess: (json: any) => Promise<S> | S,
  onFailure: (json: any, error: APIError) => F,
): (response: Response) => Promise<S | F> {
  // When checkStatus is resolved, parse JSON and pass to the onSuccess
  // callback.
  const onFulfilled = async (response: Response): Promise<S> => {
    const json = response.status === 204 ? null : await response.json()
    return onSuccess(json)
  }

  const onRejected = async (error: APIError): Promise<F> => {
    // If checkStatus throws, attempt to parse JSON. Clone response
    // so we don't consume the body.
    const json = await error.response.clone().json()
    return onFailure(json, error)
  }

  return async (response: Response): Promise<S | F> => {
    let result
    try {
      result = checkStatus(response)
    } catch (error) {
      // Only handle our own error
      if (error instanceof APIError) {
        return await onRejected(error)
      } else {
        throw error
      }
    }

    return await onFulfilled(result)
  }
}

/**
 * Return validation error object if the JSON object has 'valid' and
 * 'validation_errors keys'.
 * @param json
 */
export function getFormValidationErrors(
  json: any,
): Dictionary<string> | undefined {
  if ('valid' in json && 'validation_errors' in json && !json.valid) {
    return json.validation_errors
  }
  return undefined
}

/**
 * Can be passed as onFailure parameter of handleAppResponse to throw
 * redux-form's SubmissionError if the JSON object has valid and
 * validation_errors keys.
 * @param json
 * @param error
 */
export function handleFormSubmissionError(json: any, error: APIError): never {
  const validation_errors = getFormValidationErrors(json)
  if (validation_errors) {
    throw new SubmissionError(validation_errors)
  } else {
    // API didn't tell us what's wrong, re-throw the error.
    throw error
  }
}

export function iconForFile(file: any, props: Dictionary<any>): JSX.Element {
  const jcmFile = /log\.esa2?\..+/
  let iconName

  // bypass detection for log.esa.* files
  if (file.name.match(jcmFile)) {
    iconName = 'file-text-o'
  } else {
    iconName = mimetype2fa(file.type, { prefix: '' })
  }
  return <FontAwesomeIcon {...props} icon={iconName as any} />
}

export function pad(n: number, width = 3, z = 0): string {
  return (String(z).repeat(width) + String(n)).slice(String(n).length)
}

export function setDifference<T>(set1: Set<T>, set2: Set<T>): Set<T> {
  return new Set([...set1].filter((x) => !set2.has(x)))
}

export function formatIntDes(
  year: number,
  launch: number,
  isFailure: boolean,
): string {
  let sLaunch

  const maxLaunches = isFailure ? 99 : 999

  if (launch > maxLaunches) {
    throw new Error(
      `Launches greater than ${maxLaunches} cannot be ` +
        'expressed in this format.',
    )
  }

  if (isFailure) {
    sLaunch = `F${pad(launch, 2)}`
  } else {
    sLaunch = pad(launch, 3)
  }

  return `${year}-${sLaunch}`
}

interface IntDes {
  idyr: number
  idlno: number | null
  idfno: number | null
}

export function formatIntDesFromObj(obj: IntDes): string {
  const { idyr, idlno, idfno } = obj
  const launch = idlno || idfno
  if (launch == null) throw new Error('idlno and idfno both null')
  return formatIntDes(idyr, launch, idfno != null)
}

export async function uploadFiles(body: BodyInit, uuid: string): Promise<any> {
  const options: RequestInit = {
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
    },
    method: 'POST',
    body: body,
  }

  const storeFilesUrl = '/api/store_files'

  const query: Dictionary<any> = {}

  if (uuid) {
    query.uuid = uuid
  }

  const result = await fetch(storeFilesUrl + url.format({ query }), options)
  const handle = handleAPIResponse((json) => json, handleFormSubmissionError)

  // return fileData response
  return await handle(result)
}

export const lazyFunction =
  (f: any) =>
  (...args: any) =>
    f(...args)

/**
 * Return x.value if x is an object, otherwise x.
 */
export const idOrObjValue = (x: any): any => (_.isPlainObject(x) ? x.value : x)

/**
 * Convert keys of an object with the given transform function
 * @param transform
 * @param obj
 */
function transformKeysDeep(transform: (key: string) => string, obj: any): any {
  if (_.isArray(obj)) {
    return obj.map((x) => transformKeysDeep(transform, x))
  } else if (!_.isPlainObject(obj)) {
    return obj
  }

  return _.transform(obj, (result: any, value: any, key: string): any => {
    const transformedKey = transform(key)

    if (transformedKey !== key && transformedKey in obj) {
      throw new Error(`Transformed key already exists: ${transformedKey}`)
    }

    result[transformedKey] = transformKeysDeep(transform, value)
  })
}

export const camelizeDeep = _.partial(transformKeysDeep, _.camelCase)
export const snakifyDeep = _.partial(transformKeysDeep, _.snakeCase)

interface ObjWithId<T> extends Dictionary<any> {
  id: T
}

export interface IdObj<I extends string | number, T> {
  byId: I extends string ? { [key: string]: T } : { [key: number]: T }
  allIds: I[]
}

/**
 * Convert list of objects into an object keyed by `key`, and a list of keys
 * in the original order.
 */
export function arrayToIdObj<I extends string | number, T extends ObjWithId<I>>(
  arr: T[],
): IdObj<I, T>
export function arrayToIdObj<I extends string | number, T, K extends keyof T>(
  arr: T[],
  key: K,
): IdObj<I, T>
export function arrayToIdObj<I extends string | number, T, K extends keyof T>(
  arr: T[],
  key: any = 'id',
): IdObj<I, T> {
  const byId: any = {}
  const allIds: I[] = []

  arr.forEach((value: T): void => {
    const id = value[key as K] as unknown as I
    allIds.push(id)
    byId[id] = value
  })

  return { byId, allIds }
}

/**
 * Implementation of PostgreSQL's ltree type. The implementation does not seek
 * to implement all of the operators and functions, but is an abstraction over
 * an array with methods added as required.
 *
 * https://www.postgresql.org/docs/current/ltree.html
 */
export class Ltree<T = string> {
  readonly #labels: T[]

  constructor(labels?: Iterable<T> | ArrayLike<T>) {
    this.#labels = labels === undefined ? [] : Array.from(labels)
  }

  get length(): number {
    return this.#labels.length
  }

  slice(start?: number, end?: number): Ltree<T> {
    return new Ltree(this.#labels.slice(start, end))
  }

  get(index: number): T {
    if (index < 0 || index >= this.#labels.length) {
      throw new Error('Index out of bounds')
    }
    return this.#labels[index]
  }

  push(...values: T[]): number {
    this.#labels.push(...values)
    return this.length
  }

  concat(...values: Array<Ltree<T> | T[]>): Ltree<T> {
    return new Ltree(
      this.#labels.concat(
        ...values.map((value) =>
          value instanceof Ltree ? value.#labels : value,
        ),
      ),
    )
  }

  eq(other: Ltree<T>): boolean {
    return (
      this.length == other.length &&
      this.#labels.every((value, idx) => value === other.#labels[idx])
    )
  }

  every(
    predicate: (value: T, index: number, ltree: Ltree<T>) => unknown,
  ): boolean {
    return this.#labels.every((value: T, index: number) =>
      predicate(value, index, this),
    )
  }

  [Symbol.iterator](): Iterator<T> {
    return this.#labels[Symbol.iterator]()
  }

  toString(): string {
    return this.#labels.map(String).join('.')
  }
}

/**
 * Like Map, but has a defaultFactory attribute that is used to populate
 * keys that don't exist when .get() is called.
 */
export class DefaultMap<K, V> extends Map<K, V> {
  defaultFactory: () => V

  constructor(
    defaultFactory: () => V,
    entries?: ReadonlyArray<readonly [K, V]> | null,
  ) {
    super(entries)
    this.defaultFactory = defaultFactory
  }

  get(key: K): V {
    if (!this.has(key)) {
      const value = this.defaultFactory()
      this.set(key, value)
      return value
    } else {
      // @ts-ignore - we always return V due to the default value
      return super.get(key)
    }
  }
}

/**
 * Return r length subsequences of elements from the input iterable.
 *
 * Based on the code in Python's docs for itertools.combinations
 * SPDX-License-Identifier: Python-2.0
 */
export function* combinations<T>(
  iterable: Iterable<T>,
  r: number,
): IterableIterator<T[]> {
  const pool = [...iterable]
  const n = pool.length
  if (r > n) return
  const indices = _.range(r)
  yield indices.map((i) => pool[i])
  while (true) {
    let i = null
    for (const ii of _.reverse(_.range(r))) {
      if (indices[ii] != ii + n - r) {
        i = ii
        break
      }
    }
    if (i == null) return
    indices[i] += 1
    for (const j of _.range(i + 1, r)) {
      indices[j] = indices[j - 1] + 1
    }
    yield indices.map((i) => pool[i])
  }
}

/**
 * Parse the epoch string, trying ISO and SQL format.
 */
export function parseEpoch(epochString: string): DateTime | undefined {
  try {
    return parseCodec(DateTimeFromString, epochString)
  } catch (err) {
    return undefined
  }
}

const reBoxLike = /\b(box|ell|irr|poly)\b/i
const reConeCyl = /\b(cone|cyl)\b/i
const reSphere = /\b(sphere)\b/i
const reTorus = /\b(torus)\b/i

export type Dimension = 'height' | 'length' | 'depth' | 'diameter'

export function defaultDimensionMapForShape(shape: string): Dimension[] {
  if (shape.match(reBoxLike)) {
    return ['height', 'length', 'depth']
  } else if (shape.match(reConeCyl)) {
    return ['height', 'diameter']
  } else if (shape.match(reTorus)) {
    return ['height', 'length']
  } else if (shape.match(reSphere)) {
    return ['diameter']
  } else {
    return ['height', 'length', 'depth']
  }
}

export function exhaustiveCheck(param: never): never {
  throw new Error(`unreachable: ${param}`)
}

export function urlWithQuery(
  url: string,
  query: ConstructorParameters<typeof URLSearchParams>[0],
): string {
  const searchParams = new URLSearchParams(query)
  const q = searchParams.toString()
  return q ? `${url}?${q}` : url
}
