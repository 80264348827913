/**
 * This is the first TypeScript reducer, which is why it is a bit redundant
 * with the project reducer. Once everything is TypeScript it will be easier
 * to refactor.
 */
import {
  createAction,
  createAsyncThunk,
  createReducer,
  createSelector,
} from '@reduxjs/toolkit'
import _ from 'lodash'

import type { Frame } from '../types'
import { handleAPIResponse } from '../util'

import { handleAppError } from './error'
import type { AppDispatch, RootState } from './index'

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
}>()

export const fetchFrames = createAppAsyncThunk(
  'process/frames/fetchFrames',
  async (ifRequired: boolean, { dispatch }) => {
    const response = await fetch('/api/frames')
    const handle = handleAPIResponse(
      (json) => json,
      (_, error) => {
        dispatch(handleAppError(error))
      },
    )
    return await handle(response)
  },
  {
    condition: (ifRequired, { getState }) => {
      if (ifRequired) {
        const state = getState()
        const frames = framesSelector(state)
        const isLoading = framesLoadingSelector(state)
        if (isLoading || !_.isEmpty(frames)) return false
      }
      return undefined
    },
  },
)

export const setProcessVersionTooOld = createAction(
  'process/setProcessVersionTooOld',
)
export const clearProcessVersionTooOld = createAction(
  'process/clearProcessVersionTooOld',
)

interface ProcessState {
  frames: Frame[]
  framesLoading: boolean
  processVersionTooOld: boolean
}

const initialState: ProcessState = {
  frames: [],
  framesLoading: false,
  processVersionTooOld: false,
}

const processReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchFrames.pending, (state) => {
      state.framesLoading = true
    })
    .addCase(fetchFrames.fulfilled, (state, action) => {
      state.frames = action.payload
      state.framesLoading = false
    })
    .addCase(setProcessVersionTooOld, (state) => {
      state.processVersionTooOld = true
    })
    .addCase(clearProcessVersionTooOld, (state) => {
      state.processVersionTooOld = false
    })
})

export default processReducer

export const framesLoadingSelector = (state: RootState): boolean =>
  state.process.framesLoading
export const framesSelector = (state: RootState): Frame[] =>
  state.process.frames

export const framesByIdSelector = createSelector(
  framesSelector,
  (frames): Record<number, Frame> => _.keyBy(frames, 'id'),
)
