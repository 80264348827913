/**
 * This module provides hooks for managing when to use a fixed width page layout
 * as long as any rendered components want it.
 */
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react'
import invariant from 'tiny-invariant'

export interface PageWidthParams {
  fluid: boolean
  increment: () => void
  decrement: () => void
}

const PageWidthContext = React.createContext<PageWidthParams | null>(null)

interface Props {
  children: React.ReactNode
}

/**
 * Returns the context or throws if it isn't set.
 *
 * @param consumerName - The name of the hook or component that is using the
 * context. Will be used to throw an error when used without <PageWidthProvider>
 */
function usePageWidthContext(consumerName: string): PageWidthParams {
  const context = useContext(PageWidthContext)
  if (context === null) {
    invariant(
      context !== null,
      `${consumerName} may not be used outside of <PageWidthProvider>`,
    )
  }
  return context
}

export function PageWidthProvider({ children }: Props): JSX.Element {
  const { count: preferFluid, increment, decrement } = useCounter()

  const value = {
    fluid: preferFluid > 0,
    increment,
    decrement,
  }
  return (
    <PageWidthContext.Provider value={value}>
      {children}
    </PageWidthContext.Provider>
  )
}

export interface PageWidth {
  fluid: boolean
}

/**
 * Using this hook in a component switches the page width to fluid width mode.
 */
export function useFluidPageWidth(): void {
  const { increment, decrement } = usePageWidthContext('useFluidPageWidth')

  useLayoutEffect(() => {
    increment()
    return () => {
      decrement()
    }
  }, [increment, decrement])
}

/**
 * Returns an object indicating if the page should use a fluid (full-width)
 * container.
 */
export function usePageWidth(): PageWidth {
  const { fluid } = usePageWidthContext('usePageWidth')
  return { fluid }
}

interface Counter {
  count: number
  increment: () => void
  decrement: () => void
}

export function useCounter(initial = 0): Counter {
  const [count, setCount] = useState(initial)
  const increment = useCallback(() => {
    setCount((current) => current + 1)
  }, [setCount])

  const decrement = useCallback(() => {
    setCount((current) => current - 1)
  }, [setCount])
  return { count, increment, decrement }
}
