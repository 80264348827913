import _ from 'lodash'

import type { InternationalDesignation, JcatId } from './codecs'
import type { Country, Entity, Frame, LaunchSite, Organisation } from './types'

export function formatInternationalDesignation(
  intDes: InternationalDesignation,
): string {
  let sIntDes = `${intDes.year}-${_.padStart(intDes.launch.toString(), 3, '0')}`

  if (intDes.part) {
    sIntDes += intDes.part
  } else {
    sIntDes += '???'
  }

  if (intDes.orbital) sIntDes += ' Orbital'
  if (intDes.failure) sIntDes += ' Failure'
  if (intDes.suborbital) sIntDes += ' Sub-orbital'
  if (intDes.pad_explosion) sIntDes += ' Pad explosion'
  if (intDes.endoatmospheric) sIntDes += ' Endo-atmospheric'

  return sIntDes
}

export function formatLaunchCospar(idyr: number, idlno: number): string {
  return `${idyr}-${_.padStart(idlno.toString(), 3, '0')}`
}

export function formatCospar(
  idyr: number,
  idlno: number,
  idpno: string,
): string {
  return `${idyr}-${_.padStart(idlno.toString(), 3, '0')}${idpno}`
}

export function formatJcatId(jcatId: JcatId): string {
  return `${jcatId.prefix}${_.padStart(jcatId.number.toString(), 5, '0')}`
}

export function formatOrganisation(organisation: Organisation): string {
  return `${organisation.name} [${organisation.short_code}]`
}

export function formatCountry(country: Country): string {
  const former = !!country.iso_3166_3_code
  let label = former ? '[Former] ' : ''
  label += `${country.name} [${country.alpha2_code}] [${country.alpha3_code}]`
  return label
}

export function formatEntity(entity: Entity): string {
  if (entity.type === 'country') {
    return formatCountry(entity)
  } else if (entity.type === 'organisation') {
    return formatOrganisation(entity)
  } else {
    throw new Error('unreachable')
  }
}

export function formatLaunchSite(site: LaunchSite): string {
  let label = site.name
  if (site.site_code !== null) {
    label += ` [${site.site_code}]`
  }
  return label
}

export function formatFrame(frame: Frame): string {
  return frame.name
}
