import moment from 'moment'
import PropTypes from 'prop-types'
import { Component } from 'react'
import {
  Button,
  Col,
  Glyphicon,
  Label,
  Modal,
  Panel,
  Row,
} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'

import * as paths from '../paths'

class Dashboard extends Component {
  static propTypes = {
    projects: PropTypes.array.isRequired,
    fetchProjects: PropTypes.func.isRequired,
    showDeleteModal: PropTypes.func.isRequired,
    hideDeleteModal: PropTypes.func.isRequired,
    deleteProject: PropTypes.func.isRequired,
    deleteModalData: PropTypes.object.isRequired,
  }

  UNSAFE_componentWillMount() {
    this.props.fetchProjects()
  }

  doDelete = () => {
    this.props.deleteProject(this.props.deleteModalData.project.id)
    this.props.hideDeleteModal()
  }

  render() {
    const projectPanels = this.props.projects.map((project) => (
      <Col key={project.id} xs={12} sm={6} md={4} lg={3}>
        <ProjectPanel
          project={project}
          showDeleteModal={() => this.props.showDeleteModal(project)}
        />
      </Col>
    ))

    let modal

    if (this.props.deleteModalData.show) {
      const deleteModalProject = this.props.deleteModalData.project
      modal = (
        <Modal
          show={this.props.deleteModalData.show}
          onHide={this.props.hideDeleteModal}
          bsSize="small"
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete project?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete {deleteModalProject.name}?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.hideDeleteModal}>Cancel</Button>
            <Button onClick={this.doDelete} bsStyle="danger">
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }

    return (
      <div>
        <div>
          <div className="tw-flex tw-justify-between tw-mb-4 tw-mt-8">
            <h2 className="tw-m-0">JMD Projects</h2>
            <LinkContainer to={paths.newJcm({})}>
              <Button bsStyle="primary">New Project</Button>
            </LinkContainer>
          </div>
          <br />
          <Row>{projectPanels}</Row>
        </div>
        {modal}
      </div>
    )
  }
}

function ProjectPanel({ project, showDeleteModal }) {
  const inserted =
    project.timestamps?.inserted != null
      ? moment(project.timestamps.inserted)
      : null

  return (
    <Panel>
      <Panel.Heading>
        <div>
          <Link to={paths.jcmProject({ projectId: project.id.toString() })}>
            {project.name}
          </Link>
          {inserted === null ? (
            <span className="pull-right">
              <Button
                bsSize="xsmall"
                bsStyle="danger"
                onClick={showDeleteModal}
              >
                <Glyphicon glyph="trash" />
              </Button>
            </span>
          ) : null}
        </div>
      </Panel.Heading>
      <Panel.Body>
        <ul className="tw-mb-0">
          <li>
            Created by:{' '}
            {project.created_by.name !== null ? (
              project.created_by.name
            ) : (
              <Label>deleted user</Label>
            )}
          </li>
          {inserted !== null ? (
            <li>Inserted: {inserted.format('YYYY-MM-DD HH:mm:ss')}</li>
          ) : null}
        </ul>
      </Panel.Body>
    </Panel>
  )
}

ProjectPanel.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    created_by: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    timestamps: PropTypes.shape({
      inserted: PropTypes.string,
    }),
  }).isRequired,
  showDeleteModal: PropTypes.func.isRequired,
}

export default Dashboard
