import PropTypes from 'prop-types'
import * as React from 'react'
import { connect } from 'react-redux'
import { Route as ReactRouterRoute, Redirect } from 'react-router'

import ErrorBoundary from '../components/error-boundary'
import * as paths from '../paths'

class Route extends React.Component {
  static propTypes = {
    // component is passed, not rendered, so it is of type func
    component: PropTypes.elementType,
    children: PropTypes.node,
    username: PropTypes.string,
    location: PropTypes.any,
    private: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    private: true,
  }

  render() {
    const {
      component: Component,
      children,
      username,
      private: private_,
      ...routeProps
    } = this.props

    return (
      <ReactRouterRoute
        {...routeProps}
        render={(props) =>
          !private_ || username ? (
            <ErrorBoundary>
              {Component ? <Component {...props} /> : children}
            </ErrorBoundary>
          ) : (
            <Redirect
              to={{
                pathname: paths.login({}),
                state: { from: props.location },
              }}
            />
          )
        }
      />
    )
  }
}

const mapStateToProps = (state) => ({
  username: state.auth.username,
})

export default connect(mapStateToProps)(Route)
