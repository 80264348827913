import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Fragment } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'

import App from './components/app'
import { PageWidthProvider } from './page-width'

const queryClient = new QueryClient()

export default function createRoutes(store) {
  return (
    <Provider store={store}>
      <Fragment>
        <PageWidthProvider>
          <BrowserRouter>
            <QueryParamProvider ReactRouterRoute={Route}>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <App />
              </QueryClientProvider>
            </QueryParamProvider>
          </BrowserRouter>
        </PageWidthProvider>
      </Fragment>
    </Provider>
  )
}
