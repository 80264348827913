import { isRight } from 'fp-ts/Either'
import type * as t from 'io-ts'
import reporter from 'io-ts-reporters'

export * from './codecs'
export * from './combinators'
export * from './fields'
export * from './formatters'
export * from './types'

export interface Dictionary<T> {
  [key: string]: T
}

/**
 * Parse unknown input as an io-ts type (codec) or throw an error.
 */
export function parseCodec<T>(codec: t.Type<T, unknown>, input: unknown): T {
  const e = codec.decode(input)
  if (isRight(e)) {
    return e.right
  } else {
    throw new Error(reporter.report(e).join('\n'))
  }
}
