export class APIError extends Error {
  constructor(message, response) {
    super(message)
    this.response = response
  }
}

/**
 * This error class can be raised in a promise chain that ends with
 * .catch(handleAppError), and handleAppError will throw the contents.
 */
export class PassthroughContentsError extends Error {
  constructor(contents) {
    super()
    this.contents = contents
  }
}
