import * as either from 'fp-ts/Either'
import { pipe } from 'fp-ts/pipeable'
import * as t from 'io-ts'
import { DateTime } from 'luxon'

import { Ltree } from '../util'

import { nullable, optional } from './combinators'
import type { Range } from './types'

export type LtreeC<C extends t.Mixed> = t.Type<
  Ltree<t.TypeOf<C>>,
  Ltree<t.OutputOf<C>>,
  unknown
>

export const ltree = <C extends t.Mixed>(
  item: C,
  name = `Ltree<${item.name}>`,
): LtreeC<C> =>
  new t.Type(
    name,
    (u): u is Ltree<t.TypeOf<C>> => u instanceof Ltree && u.every(item.is),
    (u, c) =>
      u instanceof Ltree && u.every(item.is) ? t.success(u) : t.failure(u, c),
    t.identity,
  )

export type LtreeFromStringC<C extends t.Mixed> = t.Type<
  Ltree<t.TypeOf<C>>,
  string,
  unknown
>

export const ltreeFromString = <C extends t.Mixed>(
  item: C,
  name = `LtreeFromString<${item.name}>`,
): LtreeFromStringC<C> => {
  const ltreeCodec = ltree(item)
  const arrayCodec = t.array(item)
  return new t.Type(
    name,
    ltreeCodec.is,
    (u, c) =>
      pipe(
        t.string.validate(u, c),
        either.chain((s) => {
          // split on empty string returns [''] instead of [], so special case it
          return s === '' ? t.success([]) : arrayCodec.validate(s.split('.'), c)
        }),
        either.map((arr) => new Ltree(arr)),
      ),
    (ltree) => ltree.toString(),
  )
}

export type NumberFromStringC = t.Type<number, string, unknown>

export const NumberFromString: NumberFromStringC = new t.Type<
  number,
  string,
  unknown
>(
  'NumberFromString',
  t.number.is,
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      either.chain((s) => {
        const n = +s
        return isNaN(n) || s.trim() === '' ? t.failure(u, c) : t.success(n)
      }),
    ),
  String,
)

export type DateTimeFromStringC = t.Type<DateTime, string, unknown>

export const DateTimeFromString: DateTimeFromStringC = new t.Type<
  DateTime,
  string,
  unknown
>(
  'DateTimeFromString',
  (u): u is DateTime => DateTime.isDateTime(u),
  (u, c) =>
    pipe(
      t.string.validate(u, c),
      either.chain((s) => {
        let d = DateTime.fromSQL(s, { zone: 'utc' })
        if (!d.isValid) {
          d = DateTime.fromISO(s, { zone: 'utc' })
        }
        return d.isValid ? t.success(d) : t.failure(u, c)
      }),
    ),
  (d) => d.toUTC().toSQL({ includeOffset: false }),
)

export const ChangeType = t.union([
  t.literal('nochange-equal'),
  t.literal('nochange-update'),
  t.literal('nochange-noupdate'),
  t.literal('nochange-discosonly'),
  t.literal('change-equal'),
  t.literal('change-notequal'),
  t.literal('change-process'),
])

export type ChangeType = t.TypeOf<typeof ChangeType>

export const DiffType = t.union([
  t.literal('additions'),
  t.literal('changes'),
  t.literal('deletions'),
  t.literal('new'),
  t.literal('deleted'),
])

export type DiffType = t.TypeOf<typeof DiffType>

export const JmdManoeuvreCapability = t.union([
  t.literal('NOT_MANOEUVRABLE'),
  t.literal('MANOEUVRABLE'),
  t.literal('DEORBIT_ENGINE_ONLY'),
  t.literal('EOL_SAIL_ONLY'),
])

export type JmdManoeuvreCapability = t.TypeOf<typeof JmdManoeuvreCapability>

export const diffValue = <C extends t.Mixed>(
  codec: C,
): t.Type<DiffValue<t.TypeOf<C>>> =>
  t.intersection([
    t.type({
      value: nullable(codec),
    }),
    t.partial({
      error: t.string,
    }),
  ])

export interface DiffValue<T> {
  value: T | null
  error?: string
}

export const diffField = <C extends t.Mixed>(
  codec: C,
): t.Type<DiffField<t.TypeOf<C>>> =>
  t.intersection([
    t.type({
      type: ChangeType,
      discos: diffValue(codec),
      is_override: t.boolean,
      override_value: nullable(codec),
      ignore: t.boolean,
    }),
    t.partial({
      jmd: diffValue(codec),
      prev_jmd: optional(diffValue(codec)),
    }),
  ])

export interface DiffField<T> {
  type: ChangeType
  discos: DiffValue<T>
  jmd?: DiffValue<T>
  prev_jmd?: DiffValue<T>
  is_override: boolean
  override_value: T | null
  ignore: boolean
}

export const InternationalDesignation = t.type({
  year: t.number,
  launch: t.number,
  part: t.string,
  orbital: t.boolean,
  failure: t.boolean,
  suborbital: t.boolean,
  pad_explosion: t.boolean,
  endoatmospheric: t.boolean,
})

export type InternationalDesignation = t.TypeOf<typeof InternationalDesignation>

export const JcatId = t.type({
  number: t.number,
  prefix: t.string,
})

export type JcatId = t.TypeOf<typeof JcatId>

const BaseDiffItem = t.type({
  id: t.string,
  metadata: t.type({
    diff_type: DiffType,
    marked_done: t.boolean,
    ignore: t.boolean,
    last_insertion_error: nullable(t.string),
  }),
})

export const ObjectDiffItem = t.intersection([
  BaseDiffItem,
  t.type({
    type: t.literal('objects'),
    attributes: t.type({
      jcat_id: JcatId,
      international_designation: InternationalDesignation,
      current_dim1: nullable(t.number),
      current_dim2: nullable(t.number),
      current_dim3: nullable(t.number),
      current_span: nullable(t.number),
      suggested_shape_fix: nullable(t.string),
    }),
    diff: t.intersection([
      t.type({
        abs_obj_type: diffField(t.string),
        abs_obj_id: diffField(t.number),
        abs_obj_name: diffField(t.string),
        abs_obj_ascii_name: diffField(t.string),
        abs_obj_alt_names: diffField(t.array(t.string)),
        abs_obj_native_name: diffField(t.string),
        obj_name: diffField(t.string),
        obj_ascii_name: diffField(t.string),
        obj_alt_names: diffField(t.array(t.string)),
        obj_native_name: diffField(t.string),
        shape: diffField(t.string),
        height: diffField(t.number),
        length: diffField(t.number),
        depth: diffField(t.number),
        diameter: diffField(t.number),
        dimension_map: diffField(t.array(t.string)),
        span: diffField(t.number),
        wet_mass: diffField(t.number),
        dry_mass: diffField(t.number),
        mission: diffField(t.string),
        states: diffField(t.array(t.number)),
        manufacturers: diffField(t.array(t.number)),
        operators: diffField(t.array(t.number)),
        mro_from_id: diffField(t.number),
        debris_from_id: diffField(t.number),
        fragmented_from_id: diffField(t.number),
        manoeuvrability: diffField(JmdManoeuvreCapability),
      }),
      t.partial({
        bus_type: diffField(t.string),
      }),
    ]),
  }),
])

export type ObjectDiffItem = t.TypeOf<typeof ObjectDiffItem>

export const InitialOrbitDiffItem = t.intersection([
  BaseDiffItem,
  t.type({
    type: t.literal('initial_orbits'),
    attributes: t.type({
      jcat_id: JcatId,
      orbit_id: nullable(t.number),
      discos_id: t.number,
      launch_idyr: nullable(t.number),
      launch_idlno: nullable(t.number),
      launch_epoch: nullable(DateTimeFromString),
      associated_object_id: t.string,
    }),
    diff: t.type({
      epoch: diffField(t.string), // TODO: parse as datetime?
      inc: diffField(t.number),
      a_per: diffField(t.number),
      ecc: diffField(t.number),
      sma: diffField(t.number),
      frame_id: diffField(t.number),
    }),
  }),
])

export type InitialOrbitDiffItem = t.TypeOf<typeof InitialOrbitDiffItem>

export const ReentryDiffItem = t.intersection([
  BaseDiffItem,
  t.type({
    type: t.literal('reentries'),
    attributes: t.type({
      jcat_id: JcatId,
      international_designation: InternationalDesignation,
      discos_id: nullable(t.number),
      associated_object_id: t.string,
    }),
    diff: t.type({
      epoch: diffField(t.string), // TODO: parse as datetime?
    }),
  }),
])

export type ReentryDiffItem = t.TypeOf<typeof ReentryDiffItem>

export const LaunchDiffItem = t.intersection([
  BaseDiffItem,
  t.type({
    type: t.literal('launches'),
    attributes: t.intersection([
      t.type({
        idyr: t.number,
        launch_id: nullable(t.number),
      }),
      t.union([
        t.type({ idlno: t.number, idfno: t.null }),
        t.type({ idlno: t.null, idfno: t.number }),
      ]),
    ]),
    diff: t.type({
      epoch: diffField(t.string), // TODO: parse as datetime?
      flight_no: diffField(t.string),
      failure: diffField(t.boolean),
      site_id: diffField(t.number),
      vehicle_node_id: diffField(t.number),
    }),
  }),
])

export type LaunchDiffItem = t.TypeOf<typeof LaunchDiffItem>

export const FootnoteDiffItem = t.intersection([
  BaseDiffItem,
  t.type({
    type: t.literal('footnotes'),
    attributes: t.type({
      jcat_id: JcatId,
      discos_id: nullable(t.number),
      associated_object_id: t.string,
    }),
    diff: t.type({
      note: diffField(t.string),
    }),
  }),
])

export type FootnoteDiffItem = t.TypeOf<typeof FootnoteDiffItem>

export type DiffCategory =
  | 'objects'
  | 'initial_orbits'
  | 'reentries'
  | 'launches'
  | 'footnotes'

export type CategoryDiffItem<C extends DiffCategory> = C extends 'objects'
  ? ObjectDiffItem
  : C extends 'initial_orbits'
    ? InitialOrbitDiffItem
    : C extends 'reentries'
      ? ReentryDiffItem
      : C extends 'launches'
        ? LaunchDiffItem
        : C extends 'footnotes'
          ? FootnoteDiffItem
          : never

export type DiffItem =
  | ObjectDiffItem
  | InitialOrbitDiffItem
  | ReentryDiffItem
  | LaunchDiffItem
  | FootnoteDiffItem

export const categoryItemCodecs: Record<DiffCategory, t.Mixed> = {
  objects: ObjectDiffItem,
  initial_orbits: InitialOrbitDiffItem,
  reentries: ReentryDiffItem,
  launches: LaunchDiffItem,
  footnotes: FootnoteDiffItem,
}

export const ObjectError = t.intersection([
  t.type({
    message: t.string,
    cospar_id: nullable(t.string),
  }),
  t.partial({
    satno: t.number,
    jmd_id: t.string,
  }),
])

export type ObjectError = t.TypeOf<typeof ObjectError>

export const InitialOrbitError = t.type({
  message: t.string,
  satno: t.number,
})

export type InitialOrbitError = t.TypeOf<typeof InitialOrbitError>

export const ReentryError = t.intersection([
  t.type({
    message: t.string,
    cospar_id: t.string,
  }),
  t.partial({
    satno: t.number,
    jmd_id: t.string,
  }),
])

export type ReentryError = t.TypeOf<typeof ReentryError>

export const FootnoteError = t.type({
  message: t.string,
})

export type FootnoteError = t.TypeOf<typeof FootnoteError>

export const LaunchError = t.type({
  message: t.string,
})

export type LaunchError = t.TypeOf<typeof LaunchError>

export const categoryErrorCodecs: Record<DiffCategory, t.Mixed> = {
  objects: ObjectError,
  initial_orbits: InitialOrbitError,
  reentries: ReentryError,
  launches: LaunchError,
  footnotes: FootnoteError,
}

export type DiffError =
  | ObjectError
  | InitialOrbitError
  | ReentryError
  | LaunchError
  | FootnoteError

export type CategoryError<C extends DiffCategory> = C extends 'objects'
  ? ObjectError
  : C extends 'initial_orbits'
    ? InitialOrbitError
    : C extends 'reentries'
      ? ReentryError
      : C extends 'launches'
        ? LaunchError
        : C extends 'footnotes'
          ? FootnoteError
          : never

export const diffResponse = <T extends t.Mixed, E extends t.Mixed>(
  itemCodec: T,
  errorCodec: E,
): t.Type<DiffResponse<t.TypeOf<T>, t.TypeOf<E>>> =>
  t.type({
    items: t.array(itemCodec),
    errors: t.array(errorCodec),
  })

export interface DiffResponse<T, E> {
  items: T[]
  errors: E[]
}

export const range = <C extends t.Mixed>(
  codec: C,
): t.Type<Range<t.TypeOf<C>>, Range<t.OutputOf<C>>> =>
  t.type({
    lower: nullable(codec),
    upper: nullable(codec),
    bounds: t.union([
      t.literal('[]'),
      t.literal('[)'),
      t.literal('(]'),
      t.literal('()'),
    ]),
  })

export interface PathHistory {
  id: number
  discosId: number
  path: Ltree<number>
  epochRange: Range<DateTime>
  parentStatus: string | null
  closedStatus: string | null
  fragmentedFromId: number | null
  mroFromId: number | null
  debrisFromId: number | null
}

export interface PathHistoryOutput {
  id: number
  discosId: number
  path: string
  epochRange: Range<string>
  parentStatus: string | null
  closedStatus: string | null
  fragmentedFromId: number | null
  mroFromId: number | null
  debrisFromId: number | null
}

export const PathHistory: t.Type<PathHistory, PathHistoryOutput> = t.type({
  id: t.number,
  discosId: t.number,
  path: ltreeFromString(NumberFromString),
  epochRange: range(DateTimeFromString),
  parentStatus: nullable(t.string),
  closedStatus: nullable(t.string),
  fragmentedFromId: nullable(t.number),
  mroFromId: nullable(t.number),
  debrisFromId: nullable(t.number),
})
